import React from 'react';
import { IconButton } from './IconButton';
import './InformationIconButton.scss';
import { useCombineClassNames } from '../../../hooks/useCombineClassNames';

interface IProps {
    onClick: () => void;
    withMargin?: boolean;
    className?: string;
}
export const InformationIconButton = ({ onClick, withMargin = true, ...rest }: IProps) => {
    return (
        <IconButton className={useCombineClassNames(`information-icon-button${withMargin === true ? ' with-margin' : ''}`, rest)} icon={['fas', 'info-circle']} onClick={() => onClick()} />
    );
}