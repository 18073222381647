import React from 'react';
import logo from './avatar.png';
import { Image } from 'semantic-ui-react';

interface IProps extends React.HTMLProps<HTMLDivElement> {
    width?: number;
    height?: number;
}

export const JohnDoeAvatar = ({ height = 40, width = 40, ...rest }: IProps) => {
    var divStyle: React.CSSProperties = {
        height: `${height}px`,
        width: `${width}px`,
    }
    return (
        <Image className={rest.className} src={logo} avatar style={divStyle} />        
    );
}