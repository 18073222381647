import React from 'react';
import { useLocalizationContext } from '../../../hooks/useLocalizationContext';
import { FormikProps, Formik, Form, FormikActions } from 'formik';
import { IExtra, IExtraProduct } from '../../../gen/ApiClients';
import { TextLinkButton } from '../../common/buttons/TextLinkButton';
import { PrimaryButton } from '../../common/buttons/PrimaryButton';
import { SelectField } from '../../common/forms/SelectField';
import { setFieldTouched } from '../../common/forms/FormsUtils';
import { Sizes, SizesAsNumbers } from './utils';
import { toOptions } from '../../../infrastructure/Utils';
import { NumberInputField } from '../../common/forms/NumberInputField';
import { IOption } from '../../../infrastructure/Models';
import { Responsive } from 'semantic-ui-react';
import * as yup from 'yup';
import './OrderClothingForm.scss';

interface IProps {
    onSubmit: (model: IExtra) => void;
    onPrevious: () => void;
    products: IExtraProduct[];
}

export const ExtraForm = ({ onSubmit, onPrevious, products }: IProps) => {
    const locContext = useLocalizationContext();
    const validateSchema = yup.object<IExtra>().shape<IExtra>({
        quantity: yup.number()
            .integer(locContext.quantityMustBeAnInteger)
            .min(1, locContext.quantityMustBeMinimalOne)
            .required(locContext.fillInQuantity),
        description: yup.string().required(locContext.fillIn),
        size: yup.string().required(locContext.fillIn),
    });
    const onClickSubmit = async (props: FormikProps<IExtra>, e: any) => {
        e.persist();
        const isValid = await validateSchema.isValid(props.values);
        if (!isValid) {
            e.preventDefault();
        }
        setFieldTouched<IExtra>('quantity', props);
        setFieldTouched<IExtra>('description', props);
        setFieldTouched<IExtra>('size', props);
    }
    const handleSubmit = async (values: IExtra, actions: FormikActions<IExtra>) => {
        actions.setSubmitting(false);
        const x = products.find(t => t.description === values.description);
        onSubmit({...values, pricePerPiece: x ? x.price : 0});
    }

    const mustShowSizesAsNumber = (description: string) => {
        const x = products.find(t => t.description === description);
        if (x) {
            return x.hasSizeAsNumber;
        } else {
            return false;
        }
    }

    return (
        <div>
            <Formik<IExtra>
                initialValues={{}}
                isInitialValid={true}
                validationSchema={validateSchema}
                onSubmit={handleSubmit}>
                {(props) => (
                    <Form>
                        <div className="df-col ocf-container">
                            <div className="title">{locContext.extras}</div>
                            <SelectField<IExtra> options={products.map<IOption<string>>(t => ({ key: t.description ? t.description : '', value: t.description ? t.description : '', text: t.description ? `${t.description} (€ ${t.price})` : '' }))} label={locContext.select} xName="description" />
                            {props.values.description === undefined ? null :
                                mustShowSizesAsNumber(props.values.description) ?
                                    <SelectField<IExtra> options={toOptions(SizesAsNumbers)} label={locContext.size} xName="size" />
                                    :
                                    <SelectField<IExtra> options={toOptions(Sizes)} label={locContext.size} xName="size" />
                            }
                            <NumberInputField<IExtra>
                                xName="quantity"
                                label={locContext.quantity}
                                min={0}
                                step={1} />
                            <Responsive minWidth={Responsive.onlyTablet.minWidth}>
                                <div className="df-row-ac jc-e buttons-container">
                                    <div className="df-row-ac">
                                        <TextLinkButton className="cancel-button" onClick={() => onPrevious()}>{locContext.previous}</TextLinkButton>
                                        <PrimaryButton disabled={props.isSubmitting} showLoader type="submit" onClick={(e) => onClickSubmit(props, e)}>{locContext.add}</PrimaryButton>
                                    </div>
                                </div>
                            </Responsive>
                            <Responsive {...Responsive.onlyMobile}>
                                <div className="df-col buttons-container">
                                    <PrimaryButton disabled={props.isSubmitting} showLoader type="submit" onClick={(e) => onClickSubmit(props, e)}>{locContext.add}</PrimaryButton>
                                    <TextLinkButton className="cancel-button" onClick={() => onPrevious()}>{locContext.previous}</TextLinkButton>
                                </div>
                            </Responsive>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
}
