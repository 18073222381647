import { IRevenue } from "./gen/ApiClients";

export const LoginRoute = '/login';
export const ForgotPasswordRoute = '/forgot-password';
export const DashboardRoute = '/dashboard';
export const RevenuesRoute = '/revenues';
export const LeaveOfAbsencesRoute = '/leaveOfAbsences';
export const FormsRoute = '/forms';
export const SettingsRoute = '/settings';
export const SettlementProposalsRoute = '/settlementProposals';
export const RevenueDetailRoute = '/revenues/:shipmentId';
export const NotificationsRoute = '/notifications';
export const CalcRevenueDetailRoute = (inst: IRevenue): string => '/revenues/' + inst.shipmentId;
