import React, { useContext, useState, useEffect } from 'react';
import { Card, Divider, Responsive } from 'semantic-ui-react';
import { PrimaryButton } from '../common/buttons/PrimaryButton';
import { useLocalizationContext } from '../../hooks/useLocalizationContext';
import { FilterLeaveOfAbsences } from './FilterLeaveOfAbsences';
import { LeaveOfAbsencesQueryParamsContext } from '../../context/LeaveOfAbsencesQueryParamsContext';
import { LeaveOfAbsencesTable } from './LeaveOfAbsencesTable';
import { useClient } from '../../hooks/useClient';
import { LeaveOfAbsencesClient, ILeaveOfAbsencesQueryResult, LeaveOfAbsencesQueryParams, LeaveOfAbsenceModel, ILeaveOfAbsenceSummary } from '../../gen/ApiClients';
import { ModalContext } from '../../context/ModalContext';
import { LeaveOfAbsenceForm } from './LeaveOfAbsenceForm';
import { useTrigger } from '../../hooks/useTrigger';
import { LeaveOfAbsenceDetail } from './LeaveOfAbsenceDetail';
import { BaseConfirmation } from '../common/modal/BaseConfirmation';
import { WrapperSearchContext } from '../../context/WrapperSearchTextContext';
import { MonthSelector } from '../common/filter/MonthSelector';
import { WrapperFullScreenLoadContext } from '../../context/WrapperFullScreenLoadContext';
import { showSuccessToast, showErrorToast } from '../common/toast/ToastNotification';
import { JohnDoeAvatar } from '../common/johnDoeAvatar/JohnDoeAvatar';
import { toDefaultFormat, tryCatchWithLoading, tryCatch } from '../../infrastructure/Utils';
import './LeaveOfAbsences.scss';

export const LeaveOfAbsences = () => {
    const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
    const locContext = useLocalizationContext();
    const { params, setParams } = useContext(LeaveOfAbsencesQueryParamsContext);
    const client = useClient(LeaveOfAbsencesClient);
    const [queryResult, setQueryResult] = useState<ILeaveOfAbsencesQueryResult>({});
    const modalContext = useContext(ModalContext);
    const [trigger, hitTrigger] = useTrigger();
    useContext(WrapperSearchContext).hide();
    const wrapperLoaderContext = useContext(WrapperFullScreenLoadContext);

    useEffect(() => {
        load();
    }, 
    // eslint-disable-next-line
    [params, trigger]);

    const load = async () => {
        setQueryResult(await tryCatchWithLoading(client.query(new LeaveOfAbsencesQueryParams(params)), wrapperLoaderContext.setLoading, locContext.serverError));
    }

    const addLeaveOfAbsence = () => {
        modalContext.open(
            <LeaveOfAbsenceForm
                onSubmit={async (val) => {
                    modalContext.close();
                    await tryCatch(client.insert(new LeaveOfAbsenceModel(val)), locContext.serverError);
                    hitTrigger();
                    showSuccessToast(locContext.leaveOfAbsenceAdded);
                }}
                onCancel={() => modalContext.close()}
            />
            , false);
    }

    const addLeaveOfAbsenceOnDateForDriver = (date: Date, driverId: string) => {
        modalContext.open(
            <LeaveOfAbsenceForm
                model={{ driverId: driverId, fromDate: date }}
                onSubmit={async (val) => {                    
                    await tryCatch(client.insert(new LeaveOfAbsenceModel(val)), locContext.serverError);
                    hitTrigger();
                    showSuccessToast(locContext.leaveOfAbsenceAdded);
                    modalContext.close();
                }}
                onCancel={() => modalContext.close()}
            />
            , false);
    }

    const onDelete = (ids: (string | undefined)[]) => {
        modalContext.open(<BaseConfirmation
            title={locContext.deleteLeaveOfAbsence}
            description={locContext.doYouWantToDeleteThisLeaveOfAbsence}
            confirmText={locContext.yesDelete}
            cancelText={locContext.no}
            cancel={() => modalContext.close()}
            confirm={async () => {
                if (ids) {
                    let e: string | undefined = '';
                    for (let index = 0; index < ids.length; index++) {
                        const x = ids[index];
                        if (x !== undefined) {
                            const r = await tryCatch(client.delete(x), locContext.serverError);
                            if (r.hasError){
                                e = r.error;
                            }
                        }
                    }                  
                    hitTrigger();
                    if (e) {
                        showErrorToast(e);
                    } else {
                        showSuccessToast(locContext.leaveOfAbsenceDeleted);
                    }
                }
                modalContext.close();
            }}
        />)
    }

    const viewDetail = (loas: ILeaveOfAbsenceSummary[]) => {
        modalContext.open(
            <LeaveOfAbsenceDetail
                loas={loas}
                onCancel={() => modalContext.close()}
                onDelete={(id) => {
                    modalContext.close();
                    onDelete([id]);
                }}
                onDeleteAll={(ids) => {
                    modalContext.close();
                    onDelete(ids);
                }}
            />
        )
    }

    return (
        <div className="df-col">
            <Responsive {...Responsive.onlyMobile}>
                <MonthSelector
                    className="loa-month-sel"
                    afterDate={params.afterDate}
                    beforeDate={params.beforeDate}
                    onFilter={(val) => setParams({ ...params, afterDate: val.after, beforeDate: val.before })} />
            </Responsive>
            <Card className="loa-card">
                <div className="df-col stretch-ver">
                    <div className="df-row-ac jc-sb loa-header">
                        <div className="df-row-ac">
                            <PrimaryButton small onClick={() => addLeaveOfAbsence()}>+ {locContext.addLeaveOfAbsence}</PrimaryButton>
                            <Responsive minWidth={Responsive.onlyTablet.minWidth}>
                                <MonthSelector
                                    className="loa-month-sel"
                                    afterDate={params.afterDate}
                                    beforeDate={params.beforeDate}
                                    onFilter={(val) => setParams({ ...params, afterDate: val.after, beforeDate: val.before })} />
                            </Responsive>
                        </div>
                        <div className="df-row-ac">
                            <Responsive {...Responsive.onlyMobile}>
                                <PrimaryButton small outline className="filter-button" onClick={() => setIsFilterOpen(!isFilterOpen)}>{locContext.filterOn}</PrimaryButton>
                            </Responsive>
                        </div>
                    </div>
                    <Responsive {...Responsive.onlyMobile}>
                        {isFilterOpen ?
                            <div className="df-col">
                                <Divider className="no-margin" />
                                <FilterLeaveOfAbsences params={params} onFilter={(pars) => setParams(pars)} />
                                <Divider className="no-margin" />
                            </div> : <Divider className="no-margin" />}
                    </Responsive>
                    <Responsive minWidth={Responsive.onlyTablet.minWidth}>
                        <FilterLeaveOfAbsences params={params} onFilter={(pars) => setParams(pars)} />
                    </Responsive>
                    <Responsive minWidth={Responsive.onlyTablet.minWidth}>
                        <LeaveOfAbsencesTable queryResult={queryResult} onViewDetail={(loa) => viewDetail(loa)} onAdd={(date, driver) => addLeaveOfAbsenceOnDateForDriver(date, driver)} />
                    </Responsive>
                    <Responsive {...Responsive.onlyMobile}>
                        {queryResult && queryResult.values ?
                            queryResult.values.map(t =>
                                <div className="df-col" key={t.driverId}>
                                    <div className="df-col mobile-loa-grouped-by-driver" >
                                        <div className="df-row-ac d-header">
                                            <JohnDoeAvatar width={30} height={30} />
                                            <div className="driver-name">{t.driverName}</div>
                                        </div>
                                        {t.values ? t.values.map(x =>
                                            <div className="mobile-loa" onClick={() => viewDetail([x])}>
                                                {toDefaultFormat(x.date)} {x.isWholeDay ? null : `${x.fromTime} - ${x.toTime} (${x.hours})`}
                                            </div>
                                        ) : null}
                                    </div>
                                    <Divider className="no-margin" />
                                </div>
                            ) : null}
                    </Responsive>
                </div>
            </Card>
        </div>
    );
}

