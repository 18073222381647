import React from 'react';
import { useLocalizationContext } from '../../../hooks/useLocalizationContext';
import { FormikProps, Formik, Form, FormikActions } from 'formik';
import { IPackage } from '../../../gen/ApiClients';
import { TextLinkButton } from '../../common/buttons/TextLinkButton';
import { PrimaryButton } from '../../common/buttons/PrimaryButton';
import { SelectField } from '../../common/forms/SelectField';
import { setFieldTouched } from '../../common/forms/FormsUtils';
import { Sizes } from './utils';
import { toOptions } from '../../../infrastructure/Utils';
import * as yup from 'yup';
import './OrderClothingForm.scss';
import { NumberInputField } from '../../common/forms/NumberInputField';
import { Responsive } from 'semantic-ui-react';

interface IProps {
    onSubmit: (model: IPackage) => void;
    onPrevious: () => void;
}

export const PackageForm = ({ onSubmit, onPrevious }: IProps) => {
    const locContext = useLocalizationContext();
    const validateSchema = yup.object<IPackage>().shape<IPackage>({
        quantity: yup.number()
            .integer(locContext.quantityMustBeAnInteger)
            .min(1, locContext.quantityMustBeMinimalOne)
            .required(locContext.fillInQuantity),
        sizeTShirt: yup
            .string()
            .required(locContext.fillIn),
        sizePolo: yup
            .string()
            .required(locContext.fillIn),
        sizeSweatShirt: yup
            .string()
            .required(locContext.fillIn),
    });
    const onClickSubmit = async (props: FormikProps<IPackage>, e: any) => {
        e.persist();
        const isValid = await validateSchema.isValid(props.values);
        if (!isValid) {
            e.preventDefault();
        }
        setFieldTouched<IPackage>('quantity', props);
        setFieldTouched<IPackage>('sizeTShirt', props);
        setFieldTouched<IPackage>('sizePolo', props);
        setFieldTouched<IPackage>('sizeSweatShirt', props);
    }
    const handleSubmit = async (values: IPackage, actions: FormikActions<IPackage>) => {
        actions.setSubmitting(false);
        onSubmit({...values, pricePerPackage: 30});
    }

    return (
        <div>
            <Formik<IPackage>
                initialValues={{}}
                isInitialValid={true}
                validationSchema={validateSchema}
                onSubmit={handleSubmit}>
                {(props) => (
                    <Form>
                        <div className="df-col ocf-container">
                            <div className="title">{locContext.startersPackage} ({locContext.price}: &euro; 30.00)</div>
                            <NumberInputField<IPackage>
                                xName="quantity"
                                label={locContext.quantity}
                                min={0}
                                step={1} />
                            <SelectField<IPackage> options={toOptions(Sizes)} label={locContext.sizeTShirt} xName="sizeTShirt" />
                            <SelectField<IPackage> options={toOptions(Sizes)} label={locContext.sizePolo} xName="sizePolo" />
                            <SelectField<IPackage> options={toOptions(Sizes)} label={locContext.sizeSweatShirt} xName="sizeSweatShirt" />                            
                            <Responsive minWidth={Responsive.onlyTablet.minWidth}>
                                <div className="df-row-ac jc-e buttons-container">
                                    <div className="df-row-ac">
                                        <TextLinkButton className="cancel-button" onClick={() => onPrevious()}>{locContext.previous}</TextLinkButton>
                                        <PrimaryButton disabled={props.isSubmitting} showLoader type="submit" onClick={(e) => onClickSubmit(props, e)}>{locContext.add}</PrimaryButton>
                                    </div>
                                </div>
                            </Responsive>
                            <Responsive {...Responsive.onlyMobile}>
                                <div className="df-col buttons-container">
                                    <PrimaryButton disabled={props.isSubmitting} showLoader type="submit" onClick={(e) => onClickSubmit(props, e)}>{locContext.add}</PrimaryButton>
                                    <TextLinkButton className="cancel-button" onClick={() => onPrevious()}>{locContext.previous}</TextLinkButton>
                                </div>
                            </Responsive>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
}
