import React, { useState, useEffect } from 'react';
import { useClient } from '../hooks/useClient';
import { IStringNamedEntity, ServicePartnersClient } from '../gen/ApiClients';

interface IProps {
	allFilterableDrivers: IStringNamedEntity[];
}

const fallbackProps: IProps = {
	allFilterableDrivers: [],
};

export const FilterDataContext = React.createContext<IProps>(fallbackProps);

export const FilterDataProvider = ({ children }: any) => {
	const [allFilterableDrivers, setAllFilterableDrivers] = useState<IStringNamedEntity[]>([]);
	const client = useClient(ServicePartnersClient);

	useEffect(
		() => {
			load();
		},
		// eslint-disable-next-line
		[]
	);

	const load = async () => {
		setAllFilterableDrivers(await client.allFilterableDrivers());
	};
	return (
		<FilterDataContext.Provider
			value={{
				allFilterableDrivers: allFilterableDrivers,
			}}>
			{children}
		</FilterDataContext.Provider>
	);
};
