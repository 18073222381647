import { IStrings } from './IStrings';

export const nl: IStrings = {
    add: `Voeg toe`,
    addExtra: `Voeg extra toe`,
    addLeaveOfAbsence: `Voeg afwezigheid toe`,
    addStartersPack: `Voeg starterspakket toe`,
    after: `Na`,
    all: `Alles`,
    amountExVat: `Bedrag (ex. BTW)`,
    amountInVat: `Bedrag (in. BTW)`,
    assignment: `Opdracht`,
    at: `Om`,
    backToLogin: `Terug naar login`,
    backToOverview: `Terug naar overzicht`,
    before: `Voor`,
    cancel: `Annuleer`,
    canceled: `Geannuleerd`,
    coc: `Gedragscode`,
    confirmedByDriver: `Bevestigd door chauffeur`,
    contactUs: `Contacteer ons.`,
    dashboard: `Dashboard`,
    date: `Datum`,
    deadFreight: `Foutvracht`,
    delete: `Verwijderen`,
    deleteAll: `Alles verwijderen`,
    deleteFilter: `Verwijder filter`,
    deleteLeaveOfAbsence: `Afwezigheid verwijderen`,
    departed: `Vertrokken`,
    description: `Beschrijving`,
    dispute: `Betwisten`,
    disputeData: `Data betwisten`,
    disputeFormSubmitted: `Betwisting ingediend`,
    disputes: `Betwistingen`,
    download: `Download`,
    downloadTrackAndTrace: `Download Track & Trace`,
    doYouWantToDeleteThisLeaveOfAbsence: `Wil je deze afwezigheid definitief verwijderen?`,
    driver: `Chauffeur`,
    driverMobilePhone: `GSM nummer chauffeur`,
    drivers: `Chauffeurs`,
    endDate: `Einddatum`,
    endTime: `Einduur`,
    export: `Exporteer`,
    extras: `Extras`,
    extraStop: `Extra stop`,
    exVat: `ex. BTW`,
    fileNumber: `Dossiernummer`,
    fillIn: `Vul in`,
    fillInQuantity: `Vul aantal in`,
    filterOn: `Filter op`,
    firstStop: `Eerste stop`,
    forgotPassword: `Wachtwoord vergeten?`,
    forgotPasswordContent: `Stuur een mail met je gebruikersnaam\nnaar info@eurosprinters.be.\nNadien krijg je instructies om je wachtwoord te wijzigen.`,
    form: `Aanvraag`,
    forms: `Aanvragen`,
    formSubmitted: `Formulier ingediend`,
    hello: `Dag`,
    hours: `Uren`,
    inProgress: `In Uitvoering`,
    internalReference: `Interne referentie`,
    invalidCombinationUsernamePassword: `Ongeldige combinatie gebruikersnaam en wachtwoord`,
    inVat: `in. BTW`,
    isRead: `Gelezen?`,
    labelDisputeMessage: `Commentaar`,
    lastMonth: `Vorige maand`,
    lastStop: `Laatste stop`,
    lastWeek: `Vorige week`,
    lastYear: `Vorig jaar`,
    leaveOfAbsence: `Afwezigheid`,
    leaveOfAbsenceAdded: `Afwezigheid toegevoegd`,
    leaveOfAbsenceDeleted: `Afwezigheid verwijderd`,
    leaveOfAbsenceFormTitle: `Afwezigheid toevoegen`,
    load: `Laden`,
    loadAndUnloadAddressPlusStops: `Laad- en losadres (+stops)`,
    loaded: `Geladen`,
    loadFile: `Ophalen data voor dossier {0}`,
    login: `Aanmelden`,
    loginWelcomeSubtitle: `Meld je aan met je gebruikersnaam en wachtwoord.`,
    loginWelcomeTitle: `Welkom partners`,
    logOut: `Uitloggen`,
    markAsRead: `Markeer als gelezen`,
    moreInfo: `Meer info`,
    next: `Volgende`,
    no: `Nee`,
    noAccount: `Geen account`,
    none: `Niets`,
    noNewNotifications: `Er zijn geen nieuwe meldingen`,
    noRevenues: `Geen verdiensten voor deze maand`,
    notification: `Melding`,
    notificationMarkedAsRead: `Melding gemarkeerd als gelezen`,
    notificationMarkedAsUnread: `Melding gemarkeerd als ongelezen`,
    notifications: `Meldingen`,
    optional: `Optioneel`,
    orderClothing: `Bestel kledij`,
    outsourced: `Uitbesteed`,
    pagingTable: `{0} {1} tot {2} van {3}`,
    password: `Wachtwoord`,
    previous: `Vorige`,
    price: `Prijs`,
    processedDrivers: `Operationeel afgehandeld`,
    quantity: `Aantal`,
    quantityMustBeAnInteger: `Aantal moet een geheel getal zijn`,
    quantityMustBeMinimalOne: `Aantal moet minstens 1 zijn`,
    read: `Gelezen`,
    requestClothing: `Vraag kledij aan`,
    revenues: `Verdiensten`,
    route: `Route`,
    search: `Zoek`,
    searchOnTitleOrDescription: `Zoek op titel of beschrijving`,
    select: `Selecteer`,
    send: `Verzenden`,
    sendToApp: `Verstuurd naar app`,
    serverError: `Een ongekende fout is opgetreden op de server.\nGelieve het opnieuw te proberen.\nAls de fout blijft aanhouden, gelieve Euro-Sprinters te contacteren.`,
    settings: `Instellingen`,
    settlementProposals: `Afrekeningsvoorstellen`,
    size: `Maat`,
    sizePolo: `Maat Polo`,
    sizeSweatShirt: `Maat Hoodie`,
    sizeTShirt: `Maat T-Shirt`,
    startDate: `Startdatum`,
    startersPackage: `Starterspakket`,
    startersPackages: `Starterspakketten`,
    startTime: `Startuur`,
    startToEndDate: `Startdatum - Einddatum`,
    status: `Status`,
    successfullyLoggedOut: `Succesvol uitgelogd!`,
    tc: `Algemene voorwaarden`,
    thisMonth: `Deze maand`,
    thisWeek: `Deze week`,
    thisYear: `Dit jaar`,
    title: `Titel`,
    today: `Vandaag`,
    total: `Totaal`,
    totalRevenues: `Totaal verdiensten`,
    toTimeMustBeLargerThanFromTime: `Einduur moet later zijn dan startuur`,
    unload: `Lossen`,
    unloaded: `Gelost`,
    unread: `Niet gelezen`,
    upForToday: `dit gebeurt er vandaag!`,
    userName: `Gebruikersnaam`,
    validateDate: `Selecteer datum`,
    validateDisputeMessage: `Vul commentaar in`,
    validateFromDate: `Selecteer startdatum`,
    validateFromTime: `Selecteer startuur`,
    validatePassword: `Vul jouw wachtwoord in`,
    validateSelectDriver: `Selecteer een chauffeur`,
    validateToDate: `Selecteer einddatum`,
    validateToTime: `Selecteer einduur`,
    validateUsername: `Vul jouw gebruikersnaam in`,
    vat: `BTW`,
    vehicle: `Voertuig`,
    viewAll: `Bekijk alle`,
    viewPrintVersion: `Bekijk printversie`,
    viewWebVersion: `Bekijk web`,
    wholeDay: `Volledige dag`,
    yes: `Ja`,
    yesDelete: `Ja, verwijder`,
    yesterday: `Gisteren`,
    yourRevenuesThisMonth: `Jouw verdiensten van deze maand`,
}
