import React from 'react';
import { useLocalizationContext } from '../../hooks/useLocalizationContext';
import { FilterBar } from '../common/filter/FilterBar';
import { DateFilterItem } from '../common/filter/DateFilterItem';
import { ISettlementProposalQueryParams } from '../../gen/ApiClients';

interface IProps {
    params: ISettlementProposalQueryParams;
    onFilter: (params: ISettlementProposalQueryParams) => void;
}

function isFiltered(pars: ISettlementProposalQueryParams): boolean {
    if (pars === undefined) {
        return false;
    }
    if (pars.afterDate || pars.beforeDate) {
        return true;
    }
    return false;
}

export const FilterSettlementProposals = ({ params, onFilter, }: IProps) => {
    const locContext = useLocalizationContext();
    const handleClear = () => {
        onFilter({ ...params, afterDate: undefined, beforeDate: undefined, });
    }
    return (
        <FilterBar
            onClear={() => handleClear()}
            isFiltered={isFiltered(params)}>
            <div className="df-row-ac">
                <DateFilterItem
                    undefinedLabel={locContext.date}
                    afterDate={params.afterDate}
                    beforeDate={params.beforeDate}
                    onFilter={(val) => onFilter({ ...params, afterDate: val.after, beforeDate: val.before })} />
            </div>
        </FilterBar>
    );
}