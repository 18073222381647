import React, { useContext } from 'react';
import { ReactComponent as DashboardIcon } from './icons/dashboard.svg';
import { ReactComponent as DashboardSelectedIcon } from './icons/dashboard-sel.svg';
import { ReactComponent as RevenuesIcon } from './icons/revenues.svg';
import { ReactComponent as RevenuesSelectedIcon } from './icons/revenues-sel.svg';
import { ReactComponent as FormsIcon } from './icons/forms.svg';
import { ReactComponent as FormsSelectedIcon } from './icons/forms-sel.svg';
import { ReactComponent as LoaIcon } from './icons/loa.svg';
import { ReactComponent as LoaSelectedICon } from './icons/loa-sel.svg';
import { NavLink } from 'react-router-dom';
import { useLocalizationContext } from '../../hooks/useLocalizationContext';
import * as routes from '../../routes';
import './MobileFooter.scss';
import { RouterContext } from '../../context/RouterContext';

export const MobileFooter = () => {
    const locContext = useLocalizationContext();

    return (
        <div className="df-row-ac mobile-footer-container stretch-hor">
            <MobileNavLink to={routes.DashboardRoute} text={locContext.dashboard} regularIcon={<DashboardIcon />} selectedIcon={<DashboardSelectedIcon />} />
            <MobileNavLink to={routes.RevenuesRoute} text={locContext.revenues} regularIcon={<RevenuesIcon />} selectedIcon={<RevenuesSelectedIcon />} />
            <MobileNavLink to={routes.LeaveOfAbsencesRoute} text={locContext.leaveOfAbsence} regularIcon={<LoaIcon />} selectedIcon={<LoaSelectedICon />} />
            <MobileNavLink to={routes.FormsRoute} text={locContext.forms} regularIcon={<FormsIcon />} selectedIcon={<FormsSelectedIcon />} />
        </div>
    );
}

interface IProps{
    to: string;
    regularIcon: JSX.Element;
    selectedIcon: JSX.Element;
    text: string;
}
const MobileNavLink = ({to, regularIcon, selectedIcon, text}: IProps) => {
    const routerContext = useContext(RouterContext);
    
    return (
        <NavLink className="nav-link" to={to}>
            <div className="df-col-ac">
                {routerContext.activeRoute.endsWith(to) ? selectedIcon : regularIcon}
                <span>{text}</span>
            </div>
        </NavLink>
    );
}
