import React from 'react';
import { Table } from 'semantic-ui-react';
import { isSorted, nameof } from '../../../infrastructure/Utils';
import { useTableSort } from '../../../hooks/useTableSortAndPage';
import { IHeader, ISortProps } from '../../../infrastructure/Models';
import './BaseTable.scss';

interface IProps<T, TQueryParams extends ISortProps> {
    canSort: boolean;
    headers: IHeader<T>[];
    uniqueIdentifier: keyof T;
    values: T[];
    params: TQueryParams;
    onSort: (params: TQueryParams) => void;
    fallbackSortProps?: ISortProps;
    className?: string;
    footer?: JSX.Element;
    onClickRow?: (inst: T) => void;
    formatRow?: (inst: T) => string;
}

export function BaseTableSort<T, TQueryParams extends ISortProps>({ canSort, headers, uniqueIdentifier, fallbackSortProps, values, onSort, params, footer, onClickRow, formatRow, ...rest }: IProps<T, TQueryParams>) {
    const sort = useTableSort<TQueryParams>(fallbackSortProps);
    const map = new Map<string, (inst: T) => string | JSX.Element | undefined>();
    headers.forEach(m => map.set(m.key, m.evaluate));
    // shortcut when values === null
    if (!values) {
        return null;
    }

    const evaluate = (inst: T, key: string): string | JSX.Element | undefined => {
        const val = map.get(key);
        const evaluated = val ? val(inst) : '';
        return evaluated;
    }

    const handleCellClick = (header: IHeader<T>, inst: T) => {
        if (!header.disableClick && onClickRow) {
            onClickRow(inst);
        }
    }
    
    return (
        <Table className={`base-table${rest.className ? (' ' + rest.className) : ''}`}
            sortable={canSort}
            selectable={onClickRow !== undefined}>
            <Table.Header>
                <Table.Row>
                    {headers.map(header =>
                        canSort && header.sortProperty ?
                            <Table.HeaderCell
                                key={header.text}
                                sorted={isSorted(header.sortProperty, params)}
                                onClick={() => header.sortProperty ? onSort(sort(params, nameof(header.sortProperty))) : {}}>
                                {header.text}
                            </Table.HeaderCell>
                            :
                            <Table.HeaderCell
                                key={header.text}
                                className='no-sort'>
                                {header.text}
                            </Table.HeaderCell>
                    )}
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {values.map(value =>
                    <Table.Row key={(value[uniqueIdentifier] as any).toString()}>
                        {headers.map(header =>
                            <Table.Cell
                                className={`${!header.disableClick && onClickRow ? 'can-select' : ''}${header.noPadding === true ? ' no-padding' : ''}${formatRow ? ` ${formatRow(value)}` : ''}`}
                                key={header.text}
                                onClick={(e: any) => handleCellClick(header, value)}>{evaluate(value, header.key)}</Table.Cell>
                        )}
                    </Table.Row>
                )}
            </Table.Body>
            {footer ? footer : null}
        </Table>
    );
}