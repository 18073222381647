import React, { useState } from 'react';
import { ILeaveOfAbsencesQueryParams } from '../gen/ApiClients';
import moment from 'moment';
import { LeaveOfAbsencesQueryParamsContext } from './LeaveOfAbsencesQueryParamsContext';

const DefaultParams: ILeaveOfAbsencesQueryParams = {
	afterDate: moment(new Date()).startOf('month').toDate(),
	beforeDate: moment(new Date()).endOf('month').toDate(),
};

export const LeaveOfAbsencesQueryParamsProvider = ({ children }: any) => {
	const [params, setParams] = useState<ILeaveOfAbsencesQueryParams>(DefaultParams);

	return (
		<LeaveOfAbsencesQueryParamsContext.Provider
			value={{
				params: params,
				setParams: setParams,
			}}>
			{children}
		</LeaveOfAbsencesQueryParamsContext.Provider>
	);
};
