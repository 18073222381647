import React, { useEffect, useState } from "react";
import { build } from "../build";
import { BuildClient } from "../gen/ApiClients";
import { useClient } from "../hooks/useClient";
import { clearCacheAndReloadApplication } from "../infrastructure/Utils";
import { PrimaryButton } from "./common/buttons/PrimaryButton";
import { Logo } from "./common/Logo";

export const AppUpdateWall: React.FC = ({ children }: any) => {
  const buildClient = useClient(BuildClient);
  const [isOutdated, setIsOutdated] = useState<boolean>(false);

  useEffect(() => {
    const load = async () => {
      const x = await buildClient.current();
      if (build < x) {
        setIsOutdated(true);
      }
    };
    load();
    // eslint-disable-next-line
  }, []);

  if (isOutdated) {
    return (
      <div className="df-col-ac stretch-ver jc-c">
        <Logo />
        <div
          style={{ marginTop: 20, marginBottom: 20 }}
        >{`You're using an old version of this application.`}</div>
        <PrimaryButton
          className="app-update-button"
          noStretch
          small
          rectanglePadding
          onClick={() => clearCacheAndReloadApplication()}
        >
          Click here to update
        </PrimaryButton>
      </div>
    );
  } else {
    return children;
  }
};
