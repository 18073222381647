import React from 'react';
import { IOrderDetail } from '../../gen/ApiClients';
import { useLocalizationContext } from '../../hooks/useLocalizationContext';
import { toDefaultFormat } from '../../infrastructure/Utils';
import './FormDetail.scss';

interface IProps {
    detail: IOrderDetail;
}
export const FormDetail = ({ detail }: IProps) => {
    const locContext = useLocalizationContext();
    return (
        <div className="df-col fd-container stretch-ver">
            <div className="df-col fd-inner">
                <div className="fd-title">
                    {locContext.orderClothing}
                </div>
                <div className="label">{locContext.date}</div>
                <div className="info">{toDefaultFormat(detail.createdDate)}</div>
                <div className="label">{locContext.driver}</div>
                <div className="info">{detail.driverName}</div>
                <div className="label">{locContext.driverMobilePhone}</div>
                <div className="info">{detail.mobilePhoneDriver}</div>
                {detail.extras && detail.extras.length > 0 ?
                    <div className="df-col">
                        <div className="label">{locContext.extras}</div>
                        {detail.extras.map(t =>
                            <div key={detail.extras ? detail.extras.indexOf(t) : ''} className="df-row jc-sb single-extra">
                                <div className="df-col info">
                                    {t.quantity} x {t.description} ({locContext.size}: {t.size}, {t.quantity} x &euro; {t.pricePerPiece} = &euro; {t.linePrice})
                                </div>
                            </div>)}
                    </div>
                    : null}
                {detail.starterPacks && detail.starterPacks.length > 0 ?
                    <div>
                        <div className="label">{locContext.startersPackages}</div>
                        {detail.starterPacks.map(t =>
                            <div key={detail.starterPacks ? detail.starterPacks.indexOf(t) : ''} className="df-row jc-sb single-package">
                                <div className="df-col info">
                                    <div>{t.quantity} x {locContext.startersPackage} ({t.quantity} x &euro; {t.pricePerPackage} = &euro; {t.linePrice})</div>
                                    <div>-{locContext.sizePolo}: {t.sizePolo}</div>
                                    <div>-{locContext.sizeSweatShirt}: {t.sizeSweatShirt}</div>
                                    <div>-{locContext.sizeTShirt}: {t.sizeTShirt}</div>
                                </div>
                            </div>)}
                    </div>
                    : null}
            </div>
        </div>
    );
}