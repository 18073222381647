import { Form, Formik, FormikActions } from 'formik';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Responsive } from 'semantic-ui-react';
import * as yup from 'yup';
import { AuthContext } from '../../context/AuthContext';
import { LocalizationContext } from '../../context/LocalizationContext';
import { RouterContext } from '../../context/RouterContext';
import { ILoginModel, LoginModel, UserClient } from '../../gen/ApiClients';
import { useClient } from '../../hooks/useClient';
import { useLocalizationContext } from '../../hooks/useLocalizationContext';
import { clearNullOrUndefinedValues, tryCatchWithLoading } from '../../infrastructure/Utils';
import * as routes from '../../routes';
import { PrimaryButton } from '../common/buttons/PrimaryButton';
import { TextLinkButton } from '../common/buttons/TextLinkButton';
import { FormDialog } from '../common/forms/FormDialog';
import { setFieldError } from '../common/forms/FormsUtils';
import { PasswordField } from '../common/forms/PasswordField';
import { TextInputField } from '../common/forms/TextInputField';
import { Logo } from '../common/Logo';
import './Login.scss';
import { LanguageDropDown } from '../settings/LanguageDropdown';

export const Login = () => {
    const locContext = useLocalizationContext();
    const languageContext = useContext(LocalizationContext);
    const routerContext = useContext(RouterContext);
    const authContext = useContext(AuthContext);
    const client = useClient<UserClient>(UserClient);
    const initialValues = clearNullOrUndefinedValues<ILoginModel>({ userName: '', password: '' }, LoginModel);
    const validateSchema = yup.object<ILoginModel>().shape<ILoginModel>({
        userName: yup
            .string()
            .required(locContext.validateUsername),
        password: yup
            .string()
            .required(locContext.validatePassword),
    });

    const submit = async (props: ILoginModel, actions: FormikActions<ILoginModel>) => {
        const loginResult = await tryCatchWithLoading(client.login(new LoginModel(props)), actions.setSubmitting, locContext.serverError);
        if (loginResult && !loginResult.hasError && loginResult.token) {
            authContext.login(loginResult.token);
            routerContext.history.push(routes.DashboardRoute);
        } else if (loginResult !== undefined) {
            setFieldError<ILoginModel>('password', locContext.invalidCombinationUsernamePassword, actions);
        }
        actions.setSubmitting(false);
    }
    const onContactUs = (e: any) => {
        e.preventDefault();
        const lang = languageContext.getLanguage();
        const url = lang === 'nl'
            ? 'https://www.eurosprinters.be/word-chauffeur/'
            : lang === 'en'
                ? 'https://www.eurosprinters.be/en/become-driver/'
                : 'https://www.eurosprinters.be/fr/devenir-chauffeur/';
        const link = document.createElement('a');
        link.href = url;
        document.body.appendChild(link);
        link.click();
    }

    return (
        <div className="df-col full-page-sheet">
            <Responsive minWidth={Responsive.onlyTablet.minWidth}>
                <div className="df-row-ac jc-sb login-header">
                    <Logo />
                    <div>
                        <span>{locContext.noAccount}? </span>
                        <TextLinkButton className="contact-us-button" underline onClick={onContactUs}>{locContext.contactUs}</TextLinkButton>
                        <LanguageDropDown isMobile={false} />
                    </div>
                </div>
            </Responsive>
            <Responsive {...Responsive.onlyMobile}>
                <div className="df-row-ac jc-c login-header">
                    <Logo />
                </div>
            </Responsive>
            <div className="df-col-ac login-body">
                <span className="welcome-text">{locContext.loginWelcomeTitle}</span>
                <span className="sub-welcome-text">{locContext.loginWelcomeSubtitle}</span>
                <FormDialog>
                    <Formik<ILoginModel>
                        initialValues={initialValues}
                        validationSchema={validateSchema}
                        onSubmit={submit}>
                        {(props) => (
                            <Form>
                                <div>
                                    <TextInputField<ILoginModel> label={locContext.userName} xName="userName" />
                                    <PasswordField<ILoginModel> label={locContext.password} xName="password" noMargin />
                                    <Link to={routes.ForgotPasswordRoute}>
                                        <TextLinkButton gray className="forgot-password-button">{locContext.forgotPassword}</TextLinkButton>
                                    </Link>
                                </div>
                                <PrimaryButton disabled={props.isSubmitting} showLoader type="submit">{locContext.login}</PrimaryButton>
                            </Form>
                        )}
                    </Formik>
                </FormDialog>
                <Responsive {...Responsive.onlyMobile} className="stretch-hor">
                    <div className="df-col-ac stretch-hor login-footer">
                        <div className="df-row-ac mobile-no-account">
                            <span>{locContext.noAccount}? </span>
                            <TextLinkButton underline onClick={onContactUs}>{locContext.contactUs}</TextLinkButton>
                        </div>
                        <LanguageDropDown isMobile={true} />
                    </div>
                </Responsive>
            </div>
        </div>
    );
}

