import { useContext } from 'react';
import { RouterContext } from '../../context/RouterContext';
import { WrapperFullScreenLoadContext } from '../../context/WrapperFullScreenLoadContext';
import { BuildClient, ISettlementProposal } from '../../gen/ApiClients';
import { useClient, useDownloadClient } from '../../hooks/useClient';
import { IconButton } from '../common/buttons/IconButton';
import './SettlementProposals.scss';
import React from 'react';

interface IProps {
	item: ISettlementProposal;
	isMobile: boolean;
}

export const SpsActions = ({ item, isMobile }: IProps) => {
	const buildClient = useClient(BuildClient);
	const wrapperLoaderContext = useContext(WrapperFullScreenLoadContext);
	const routerContext = useContext(RouterContext);
	const downloadClient = useDownloadClient(routerContext);

	const download = async () => {
		wrapperLoaderContext.setLoading(true);
		const baseUrl = await buildClient.url();
		const url = `${baseUrl}/api/SettlementProposals/${item.documentId}/download`;
		await downloadClient.download(url, `SP_${item.documentId}.pdf`, () => wrapperLoaderContext.setLoading(false));
	};

	return (
		<div className='df-row-ac jc-e actions-buttons'>
			<IconButton
				color='gray'
				size={isMobile ? 'very-large' : 'large'}
				icon={['far', 'file-alt']}
				onClick={e => {
					e.preventDefault();
					download();
				}}
			/>
		</div>
	);
};
