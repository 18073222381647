import { IStrings } from './IStrings';

export const en: IStrings = {
    add: `Add`,
    addExtra: `Add extra`,
    addLeaveOfAbsence: `Add leave of absence`,
    addStartersPack: `Add starters pack`,
    after: `After`,
    all: `All`,
    amountExVat: `Amount (VAT excl.)`,
    amountInVat: `Amount (VAT incl.)`,
    assignment: `Assignment`,
    at: `At`,
    backToLogin: `Back to login`,
    backToOverview: `Back to overview`,
    before: `Before`,
    cancel: `Cancel`,
    canceled: `Canceled`,
    coc: `Code of conduct`,
    confirmedByDriver: `Confirmed by driver`,
    contactUs: `Contact us.`,
    dashboard: `Dashboard`,
    date: `Date`,
    deadFreight: `Dead freight`,
    delete: `Delete`,
    deleteAll: `Delete all`,
    deleteFilter: `Delete filter`,
    deleteLeaveOfAbsence: `Delete leave of absence`,
    departed: `Departed`,
    description: `Description`,
    dispute: `Dispute`,
    disputeData: `Dispute data`,
    disputeFormSubmitted: `Dispute submitted`,
    disputes: `Disputes`,
    download: `Download`,
    downloadTrackAndTrace: `Download Track & Trace`,
    doYouWantToDeleteThisLeaveOfAbsence: `Do you want to delete this leave of absence?`,
    driver: `Driver`,
    driverMobilePhone: `Mobile phone driver`,
    drivers: `Drivers`,
    endDate: `End date`,
    endTime: `End time`,
    export: `Export`,
    extras: `Extras`,
    extraStop: `Extra stop`,
    exVat: `VAT excl.`,
    fileNumber: `Order number`,
    fillIn: `Fill in`,
    fillInQuantity: `Fill in quantity`,
    filterOn: `Filter on`,
    firstStop: `First stop`,
    forgotPassword: `Forgot password?`,
    forgotPasswordContent: `Send an email with your username to\ninfo@eurosprinters.be.\nYou'll get instructions to reset your password afterwards.`,
    form: `Form`,
    forms: `Forms`,
    formSubmitted: `Form submitted`,
    hello: `Hello`,
    hours: `Hours`,
    inProgress: `In progress`,
    internalReference: `Internal reference`,
    invalidCombinationUsernamePassword: `Invalid combination username and password`,
    inVat: `VAT incl.`,
    isRead: `Read?`,
    labelDisputeMessage: `Comments`,
    lastMonth: `Last month`,
    lastStop: `Last stop`,
    lastWeek: `Last week`,
    lastYear: `Last year`,
    leaveOfAbsence: `Leave of absence`,
    leaveOfAbsenceAdded: `Leave of absence added`,
    leaveOfAbsenceDeleted: `Leave of absence deleted`,
    leaveOfAbsenceFormTitle: `Add leave of absence`,
    load: `Load`,
    loadAndUnloadAddressPlusStops: `Loading- and unloading address (+ stops)`,
    loaded: `Loaded`,
    loadFile: `Load data for file  {0}`,
    login: `Login`,
    loginWelcomeSubtitle: `Login with your username and password`,
    loginWelcomeTitle: `Welcome partners`,
    logOut: `Log out`,
    markAsRead: `Mark as read`,
    moreInfo: `More info`,
    next: `Next`,
    no: `No`,
    noAccount: `No account`,
    none: `None`,
    noNewNotifications: `There are no new notifications`,
    noRevenues: `Pas de mérite pour ce mois`,
    notification: `Notification`,
    notificationMarkedAsRead: `Notification marked as read`,
    notificationMarkedAsUnread: `Notification marked as unread`,
    notifications: `Notifications`,
    optional: `Optional`,
    orderClothing: `Order clothing`,
    outsourced: `Outsourced`,
    pagingTable: `{0} {1} until {2} from {3}`,
    password: `Password`,
    previous: `Previous`,
    price: `Price`,
    processedDrivers: `Operational handled`,
    quantity: `Quantity`,
    quantityMustBeAnInteger: `Quantity must be a round number`,
    quantityMustBeMinimalOne: `Quantity must be minimal one`,
    read: `Read`,
    requestClothing: `Request clothing`,
    revenues: `Revenues`,
    route: `Route`,
    search: `Search`,
    searchOnTitleOrDescription: `Search on title or description`,
    select: `Select`,
    send: `Send`,
    sendToApp: `Send to app`,
    serverError: `An unknown error has occured. Please try again. If this error returns, please contact Euro-Sprinters!`,
    settings: `Settings`,
    settlementProposals: `Settlement Proposals`,
    size: `Size`,
    sizePolo: `Size polo`,
    sizeSweatShirt: `Size hoodie`,
    sizeTShirt: `Size t-shirt`,
    startDate: `Start date`,
    startersPackage: `Starters package`,
    startersPackages: `Starters packages`,
    startTime: `Start time`,
    startToEndDate: `Start date - end date`,
    status: `Status`,
    successfullyLoggedOut: `Successfully logged out!`,
    tc: `Terms and conditions`,
    thisMonth: `This month`,
    thisWeek: `This week`,
    thisYear: `This year`,
    title: `Title`,
    today: `Today`,
    total: `Total`,
    totalRevenues: `Total revenues`,
    toTimeMustBeLargerThanFromTime: `The end time must me later than the start time`,
    unload: `Unload`,
    unloaded: `Unloaded`,
    unread: `Unread`,
    upForToday: `this is happening today!`,
    userName: `Username`,
    validateDate: `Select date`,
    validateDisputeMessage: `Fill in comment`,
    validateFromDate: `Select start date`,
    validateFromTime: `Select start time`,
    validatePassword: `Fill in your password`,
    validateSelectDriver: `Select a driver`,
    validateToDate: `Select end date`,
    validateToTime: `Select end time`,
    validateUsername: `Fill in your username`,
    vat: `VAT`,
    vehicle: `Vehicle`,
    viewAll: `View all`,
    viewPrintVersion: `View print`,
    viewWebVersion: `View web`,
    wholeDay: `Whole day`,
    yes: `Yes`,
    yesDelete: `Yes, delete`,
    yesterday: `Yesterday`,
    yourRevenuesThisMonth: `Your revenues this month`,
}
