import React, { useContext } from 'react';
import { useLocalizationContext } from '../../hooks/useLocalizationContext';
import { FilterBar } from '../common/filter/FilterBar';
import { ListFilterItem } from '../common/filter/ListFilterItem';
import { IOption } from '../../infrastructure/Models';
import { FilterDataContext } from '../../context/FilterDataContext';
import { ILeaveOfAbsencesQueryParams } from '../../gen/ApiClients';

interface IProps {
    params: ILeaveOfAbsencesQueryParams;
    onFilter: (params: ILeaveOfAbsencesQueryParams) => void;
}

function isFiltered(pars: ILeaveOfAbsencesQueryParams) {
    if (!pars) {
        return false;
    }
    if (pars.driverIds && pars.driverIds.length > 0) {
        return true;
    }
    return false;
}

export const FilterLeaveOfAbsences = ({ params, onFilter, }: IProps) => {
    const locContext = useLocalizationContext();
    const context = useContext(FilterDataContext);
    const handleClear = () => {
        onFilter({ ...params, driverIds: [] });
    }
    return (
        <FilterBar
            onClear={() => handleClear()}
            isFiltered={isFiltered(params)}>
            <div className="df-row-ac df-col-if-mobile">
                <ListFilterItem<string>
                    icon={['fas', 'users']}
                    label={locContext.driver}
                    options={context.allFilterableDrivers.map<IOption<string>>(t => ({ key: t.id ? t.id : '', value: t.id ? t.id : '', text: t.value ? t.value : '' }))}
                    selection={params.driverIds ? params.driverIds : []}
                    onFilter={(val) => onFilter({ ...params, driverIds: val })} />
            </div>
        </FilterBar>
    );
}