import React from 'react';
import { Field } from 'formik';
import { FieldGroup, IFieldGroupProps } from './FieldGroup';
import './TextareaField.scss';

// export const TextareaField = (props: IFieldGroupProps) => {
export function TextareaField<T>(props: IFieldGroupProps<T>) {
    return (
        <FieldGroup {...props}>
            <Field className="text-area-field" component="textarea" name={props.xName} placeholder={props.placeholder} />
        </FieldGroup>
    );
}
