import React, { useState, useEffect } from 'react';
import { FilterItem } from './FilterItem';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { enumToOptions, toDefaultFormat, toDefaultStartOfDay, toDefaultEndOfDay } from '../../../infrastructure/Utils';
import { CustomListItem } from './CustomListItem';
import { DatesRangeInput } from 'semantic-ui-calendar-react';
import { DefaultDateFormat } from '../../../constants';
import { useLocalizationContext } from '../../../hooks/useLocalizationContext';
import { Responsive } from 'semantic-ui-react';
import moment from 'moment';
import './DateFilterItem.scss';

interface IProps {
    icon?: IconProp;
    undefinedLabel: string;
    afterDate: Date | undefined;
    beforeDate: Date | undefined;
    onFilter: (value: IDateOutput) => void;
}

enum DateFilterEnum {
    Today,
    Yesterday,
    ThisWeek,
    LastWeek,
    ThisMonth,
    LastMonth,
    ThisYear,
    LastYear,
}

const toText = (dateOutput: IDateOutput) => {
    return `${toDefaultFormat(dateOutput.after)} - ${toDefaultFormat(dateOutput.before)}`;
}

interface IDateCalc {
    after: Date;
    before: Date;
    shortCode: DateFilterEnum;
}

function calculateDates(date: Date): Map<DateFilterEnum, IDateCalc> {
    const output = new Map<DateFilterEnum, IDateCalc>();
    output.set(DateFilterEnum.Today, {
        after: moment(date).startOf("day").toDate(),
        before: moment(date).endOf("day").toDate(),
        shortCode: DateFilterEnum.Today
    });
    output.set(DateFilterEnum.Yesterday, {
        after: moment(date).add(-1, "days").startOf("day").toDate(),
        before: moment(date).add(-1, "days").endOf("day").toDate(),
        shortCode: DateFilterEnum.Yesterday
    });
    output.set(DateFilterEnum.ThisWeek, {
        after: moment(date).startOf("week").toDate(),
        before: moment(date).endOf("week").toDate(),
        shortCode: DateFilterEnum.ThisWeek
    });
    output.set(DateFilterEnum.LastWeek, {
        after: moment(date).add(-1, "weeks").startOf("week").toDate(),
        before: moment(date).add(-1, "weeks").endOf("week").toDate(),
        shortCode: DateFilterEnum.LastWeek
    });
    output.set(DateFilterEnum.ThisMonth, {
        after: moment(date).startOf("month").toDate(),
        before: moment(date).endOf("month").toDate(),
        shortCode: DateFilterEnum.ThisMonth
    });
    output.set(DateFilterEnum.LastMonth, {
        after: moment(date).add(-1, "months").startOf("month").toDate(),
        before: moment(date).add(-1, "months").endOf("month").toDate(),
        shortCode: DateFilterEnum.LastMonth
    });
    output.set(DateFilterEnum.ThisYear, {
        after: moment(date).startOf("year").toDate(),
        before: moment(date).endOf("year").toDate(),
        shortCode: DateFilterEnum.ThisYear
    });
    output.set(DateFilterEnum.LastYear, {
        after: moment(date).add(-1, "years").startOf("year").toDate(),
        before: moment(date).add(-1, "years").endOf("year").toDate(),
        shortCode: DateFilterEnum.LastYear
    });
    return output;
}

function match(calcs: Map<DateFilterEnum, IDateCalc>, after: Date | undefined, before: Date | undefined): DateFilterEnum | undefined {
    if (after === undefined || before === undefined) {
        return undefined;
    } else {
        const matched = Array.from(calcs.values()).find(t => t.after.toString() === after.toString() && t.before.toString() === before.toString());
        // const matched = Array.from(calcs.values()).find(t => t.after === after && t.before === before);
        if (matched) {
            return matched.shortCode;
        } else {
            return undefined;
        }
    }
}

interface IDateOutput {
    after: Date | undefined;
    before: Date | undefined;
}



export const DateFilterItem = ({ icon = ['fas', 'calendar'], undefinedLabel, afterDate, beforeDate, onFilter, }: IProps) => {
    const locContext = useLocalizationContext();
    const translations = new Map<DateFilterEnum, string>([
        [DateFilterEnum.Today, locContext.today],
        [DateFilterEnum.Yesterday, locContext.yesterday],
        [DateFilterEnum.ThisWeek, locContext.thisWeek],
        [DateFilterEnum.LastWeek, locContext.lastWeek],
        [DateFilterEnum.ThisMonth, locContext.thisMonth],
        [DateFilterEnum.LastMonth, locContext.lastMonth],
        [DateFilterEnum.ThisYear, locContext.thisYear],
        [DateFilterEnum.LastYear, locContext.lastYear]]);
    const dateCalcs = calculateDates(new Date());
    const options = enumToOptions<number>(DateFilterEnum, translations);
    const [shortCode, setShortCode] = useState<DateFilterEnum | undefined>();
    const [val, setVal] = useState<IDateOutput>({ after: afterDate, before: beforeDate });
    const [label, setLabel] = useState<string>(undefinedLabel);
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

    if (isSubmitted && (val.after !== afterDate || val.before !== beforeDate)) {
        setVal({ after: afterDate, before: beforeDate });
    }

    const onManualSet = (e: any, data: { value: string }) => {
        var splits = data.value.split(' - ');
        const xAfter = toDefaultStartOfDay(splits[0]);
        const xBefore = toDefaultEndOfDay(splits[1]);
        if (splits[0] && splits[1]) {
            setVal({ after: xAfter, before: xBefore });
        } else if (splits[0]) {
            setVal({ after: xAfter, before: undefined });
        } else {
            setVal({ after: undefined, before: undefined });
        }
        setShortCode(match(dateCalcs, xAfter, xBefore));
    }
    const onChangeShortCode = (shortCode: number) => {
        setShortCode(shortCode);
        const x = shortCode !== undefined ? dateCalcs.get(DateFilterEnum[shortCode] as any) : undefined;
        if (x) {
            setVal({ after: x.after, before: x.before });
        } else {
            setVal({ after: undefined, before: undefined });
        }
    }

    useEffect(() => {
        if (val.after === undefined || val.before === undefined) {
            setLabel(undefinedLabel);
            setShortCode(undefined);
        } else {
            const xx = shortCode ? translations.get(DateFilterEnum[shortCode] as any) : undefined;
            if (xx) {
                setLabel(xx);
            } else {
                setLabel(`${moment(val.after).format('DD/MM')}-${moment(val.before).format('DD/MM')}`);
            }
        }
    }, 
    // eslint-disable-next-line
    [shortCode, val]);

    const onClickAway = () => {
        if (val.after !== afterDate || val.before !== beforeDate) {
            onFilter(val);
        }
        setIsSubmitted(true);
    }

    return (
        <FilterItem
            icon={icon}
            label={label}
            count={val.after ? 1 : 0}
            onClickAway={() => onClickAway()}
            noMaxHeight={true}
            onOpened={() => setIsSubmitted(false)}>
            <div className="df-col dfi-container">
                <div className="df-row">
                    <Responsive minWidth={Responsive.onlyTablet.minWidth}>
                        <div className="df-col jc-c dfi-enum-options">
                            {options.map(t =>
                                <CustomListItem
                                    key={t.key}
                                    showCheckbox={false}
                                    label={t.text}
                                    onToggle={() => onChangeShortCode(t.value)}
                                    checked={t.value === shortCode} />)}
                        </div>
                    </Responsive>
                    <DatesRangeInput
                        dateFormat={DefaultDateFormat}
                        value={toText(val)}
                        onChange={onManualSet}
                        clearable
                        onClear={() => { }}
                        closable
                        inline={true}
                        allowSameEndDate={true} />
                </div>
            </div>
        </FilterItem>
    );
}