import React, { useContext, useState } from 'react';
import { Divider, Responsive } from 'semantic-ui-react';
import { ModalContext } from '../../context/ModalContext';
import { IRouterProps, RouterContext } from '../../context/RouterContext';
import { IRevenue, IRevenuePerAssignment, IRevenuePerDay, IRevenuesByDriver } from '../../gen/ApiClients';
import { useLocalizationContext } from '../../hooks/useLocalizationContext';
import { ISortProps } from '../../infrastructure/Models';
import { nameof, toDefaultFormat } from '../../infrastructure/Utils';
import { CalcRevenueDetailRoute } from '../../routes';
import { IconButton } from '../common/buttons/IconButton';
import { InformationIconButton } from '../common/buttons/InformationIconButton';
import { JohnDoeAvatar } from '../common/johnDoeAvatar/JohnDoeAvatar';
import { BaseTableSort } from '../common/table/BaseTableSort';
import { createHeader } from '../common/table/TableUtils';
import './RevenuePerDriver.scss';
import { RevenuesSummary } from './RevenuesSummary';

interface IProps {
    value: IRevenuesByDriver;
    afterDate: Date | undefined;
    beforeDate: Date | undefined;
    // showStatus?: boolean;
}

const initialSort: ISortProps = {
    sortProperty: nameof<IRevenue>('date'),
    sortDirection: 'ascending',
};

const viewDetails = (routerContext: IRouterProps, inst: IRevenue) => {
    routerContext.history.push(CalcRevenueDetailRoute(inst));
}

export const RevenuePerDriver = ({ value, afterDate, beforeDate }: IProps) => {
    const locContext = useLocalizationContext();
    const [isExpanded, setIsExpanded] = useState<boolean>(true);
    // const [values, setValues] = useState<IRevenue[]>(value.values ? value.values : []);
    const modalContext = useContext(ModalContext);

    // const headers = [
    //     createSortableHeader<IRevenue>(locContext.date, 'date', t => toDefaultFormat(t.date)),
    //     createHeader<IRevenue>(locContext.assignment, t => t.assignment),
    //     createHeader<IRevenue>(locContext.fileNumber, t => t.fileNumber),
    //     createHeader<IRevenue>(locContext.loadAndUnloadAddressPlusStops, t => t.formattedAddress),
    //     createSortableHeader<IRevenue>(locContext.vehicle, 'department', t => t.department),
    //     createSortableHeader<IRevenue>(locContext.amountExVat, 'amountExVat', t => <div>&euro; {t.amountExVat}</div>),
    // ]
    // if (showStatus) {
    //     headers.push(createSortableHeader<IRevenue>(locContext.status, 'status', t => t.status));
    // }

    // const handleSort = (props: ISortProps) => {
    //     setSortProps(props);
    //     if (!props.sortProperty) {
    //         setValues(value.values ? value.values : []);
    //     } else if (props.sortDirection === 'ascending') {
    //         setValues(_.sortBy(values, props.sortProperty));
    //     } else {
    //         setValues(_.sortBy(values, props.sortProperty).reverse());
    //     }
    // }


    const showTotals = () => {
        modalContext.open(
            <RevenuesSummary
                afterDate={afterDate}
                beforeDate={beforeDate}
                drivers={[value.driverName ? value.driverName : '']}
                amountExVat={value.totalAmountExVat}
                amountInVat={value.totalAmountInVat}
                vatAmount={value.totalVatAmount}
                previousMonthAmountExVat={value.previousMonthTotalAmountExVat}
                percentageChangeAmountExVat={value.percentageChangeTotalAmountExVat} />
            , true);
    }

    return (
        <div className="df-col rpd-container">
            <div className="df-row-ac jc-sb driver-header" onClick={() => setIsExpanded(!isExpanded)}>
                <div className="df-row-ac">
                    <Responsive {...Responsive.onlyMobile}>
                        <JohnDoeAvatar height={30} width={30} className="rev-avatar" />
                    </Responsive>
                    <Responsive minWidth={Responsive.onlyTablet.minWidth}>
                        <JohnDoeAvatar className="rev-avatar" />
                    </Responsive>
                    <Responsive {...Responsive.onlyMobile} className="df-col driver-name">
                        <div>{value.driverName}</div>
                        <div className="df-row-ac">
                            <div className="tm-totals" style={{ marginRight: '5px' }}>{locContext.total}: &euro; {value.totalAmountExVat}</div>
                            {/* {renderChange(value.percentageChangeTotalAmountExVat)} */}
                        </div>
                    </Responsive>
                    <Responsive minWidth={Responsive.onlyTablet.minWidth}>
                        <div className="driver-name">{value.driverName}</div>
                    </Responsive>
                </div>
                <div className="df-row-ac">
                    <>
                        <Responsive minWidth={Responsive.onlyTablet.minWidth} className="df-row-ac">
                            <div className="tm-totals" style={{ marginRight: '5px' }}>{locContext.total}: &euro; {value.totalAmountExVat}</div>
                            {/* {renderChange(value.percentageChangeTotalAmountExVat)} */}
                        </Responsive>
                        <div style={{ marginRight: '10px' }}>
                            <InformationIconButton onClick={() => showTotals()} />
                        </div>
                        {isExpanded ?
                            <IconButton className="expand-button" icon={['fas', 'chevron-down']} />
                            : <IconButton className="expand-button" icon={['fas', 'chevron-up']} />}
                    </>
                </div>
            </div>
            {isExpanded && value.values ?
                <div className="df-col">
                    {value.values.map(t => <RevenuePerDay value={t} />)}


                    {/* <Responsive minWidth={Responsive.onlyTablet.minWidth}> */}

                    {/* <BaseTableSort<IRevenue, ISortProps>
                            className="revenues-table"
                            values={values}
                            onSort={(pars) => handleSort(pars)}
                            fallbackSortProps={initialSort}
                            uniqueIdentifier='fileNumber'
                            headers={headers}
                            canSort={true}
                            params={sortProps}
                            onClickRow={(inst) => viewDetails(inst)} /> */}
                    {/* </Responsive> */}
                    {/* <Responsive {...Responsive.onlyMobile}>
                        {value.values.map(t =>
                            <div className="df-col" key={t.fileNumber} onClick={() => viewDetails(routerContext, t)}>
                                <div className="df-col mobile-single-revenue">
                                    <div className="info font-bold date">{toDefaultFormat(t.date)} - &euro; {t.amountExVat}</div>
                                    <div className="info">{t.formattedAddress}</div>
                                    <div className="info">{t.department}</div>
                                </div>
                                <Divider className="no-margin" />
                            </div>)
                            : null}
                    </Responsive> */}
                    {/* <div className="df-row-ac totals">
                        <div className="tm-totals">{locContext.total}: &euro; {value.totalAmountExVat}</div>
                        {renderChange(value.percentageChangeTotalAmountExVat)}
                        <InformationIconButton onClick={() => showTotals()} />
                    </div> */}
                </div>
                : <div className="stretch-hor empty-driver"></div>}
        </div>
    );
}

interface IRevenuePerDayProps {
    value: IRevenuePerDay;
}

export const RevenuePerDay = ({ value }: IRevenuePerDayProps) => {
    const locContext = useLocalizationContext();
    const [isExpanded, setIsExpanded] = useState<boolean>(true);

    return (
        <div className="df-col" style={{ marginBottom: '4px' }}>
            <div className="df-row-ac jc-sb per-day-header" onClick={() => setIsExpanded(!isExpanded)}>
                <div className="df-row-ac">
                    <div>{locContext.date}: <strong>{toDefaultFormat(value.date)}</strong></div>
                </div>
                <div className="df-row-ac">
                    <div className="tm-totals" style={{ marginRight: '10px' }}>{locContext.total}: &euro; {value.amountExVat}</div>
                    {isExpanded ?
                        <IconButton className="expand-button" icon={['fas', 'chevron-down']} />
                        : <IconButton className="expand-button" icon={['fas', 'chevron-up']} />}
                </div>
            </div>
            {isExpanded && value.values ? value.values.map(t => <RevenuePerAssignment value={t} />)
                : undefined}
        </div>
    );
}

interface IRevenuePerAssignmentProps {
    value: IRevenuePerAssignment;
}

export const RevenuePerAssignment = ({ value }: IRevenuePerAssignmentProps) => {
    const locContext = useLocalizationContext();
    const routerContext = useContext(RouterContext);
    const [isExpanded, setIsExpanded] = useState<boolean>(true);

    const headers = [
        // createHeader<IRevenue>(locContext.date, t => toDefaultFormat(t.date)),
        // createHeader<IRevenue>(locContext.assignment, t => t.assignment),
        createHeader<IRevenue>(locContext.fileNumber, t => t.fileNumber),
        createHeader<IRevenue>(locContext.loadAndUnloadAddressPlusStops, t => t.formattedAddress),
        createHeader<IRevenue>(locContext.vehicle, t => t.department),
        createHeader<IRevenue>(locContext.amountExVat, t => <div>&euro; {t.amountExVat}</div>),
        createHeader<IRevenue>(locContext.status, t => t.status)
    ]

    return (
        <div className="df-col" style={{ marginBottom: '4px' }}>
            <div className="df-row-ac jc-sb per-day-header" onClick={() => setIsExpanded(!isExpanded)}>
                <div className="df-row-ac">
                    {/* <FontAwesomeIcon icon={['fas', 'arrow-right']} className="right-arrow-icon" style={{ marginRight: '5px'}} /> */}
                    <div>{locContext.assignment}: <strong>{value.assignment}</strong></div>
                </div>
                <div className="df-row-ac">
                    <div className="tm-totals" style={{ marginRight: '10px' }}>{locContext.total}: &euro; {value.amountExVat}</div>
                    {isExpanded ?
                        <IconButton className="expand-button" icon={['fas', 'chevron-down']} />
                        : <IconButton className="expand-button" icon={['fas', 'chevron-up']} />}
                </div>
            </div>
            {isExpanded && value.values ?
                <>
                    <Responsive minWidth={Responsive.onlyTablet.minWidth}>
                        <BaseTableSort<IRevenue, ISortProps>
                            className="revenues-table"
                            values={value.values}
                            onSort={(pars) => { }}
                            fallbackSortProps={initialSort}
                            uniqueIdentifier='fileNumber'
                            headers={headers}
                            canSort={false}
                            params={initialSort}
                            onClickRow={(inst) => viewDetails(routerContext, inst)} />
                    </Responsive>
                    <Responsive {...Responsive.onlyMobile}>
                        {value.values.map(t =>
                            <div className="df-col" key={t.fileNumber} onClick={() => viewDetails(routerContext, t)}>
                                <div className="df-col mobile-single-revenue">
                                    <div className="info font-bold date">{t.fileNumber} - &euro; {t.amountExVat}</div>
                                    <div className="info">{t.status}</div>
                                    <div className="info">{t.formattedAddress}</div>
                                    <div className="info">{locContext.vehicle}: {t.department}</div>
                                </div>
                                <Divider className="no-margin" />
                            </div>)}
                    </Responsive>
                </>
                : undefined}
        </div>
    );
}