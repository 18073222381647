import React from 'react';
import { IStop } from '../../gen/ApiClients';
import { useLocalizationContext } from '../../hooks/useLocalizationContext';
import moment from 'moment';
import { DefaultDateFormat } from '../../constants';
import './StopViewComponent.scss';

interface ISingleProps {
    stop: IStop;
}
export const StopViewComponent = ({ stop }: ISingleProps) => {
    const locContext = useLocalizationContext();
    const stopName = stop.isFirst ? locContext.firstStop : stop.isLast ? locContext.lastStop : locContext.extraStop + ' ' + stop.extraStopOrder;
    const formatDateSpecifier = (str: string | undefined) => {
        if (str === 'At') {
            return locContext.at;
        } else if (str === 'Before') {
            return locContext.before;
        } else {
            return locContext.after;
        }
    }
    return (
        <div className="df-row jc-sb stop-view">
            <div className="df-col fg1">
                <div className="label">{stopName}</div>
                <div>{moment(stop.date, DefaultDateFormat).format(DefaultDateFormat)} {formatDateSpecifier(stop.dateSpecifier)} {moment(stop.date, DefaultDateFormat).format('HH:mm')}</div>
                <div>{stop.address ? stop.address.company + ' - ' + stop.address.contact : ''}</div>
                <div>{stop.address ? stop.address.formatted : ''}</div>
                {stop.address && stop.address.additionalInfo ? <div>{stop.address.additionalInfo}</div> : null}
                {stop.address && stop.address.phoneNumber ? <div>{stop.address.phoneNumber}</div> : null}
                {stop.address && stop.address.comment ? <div>{stop.address.comment}</div> : null}
                {stop.loadOperations ? stop.loadOperations.map(t => <div key={t}>{locContext.load}: {t}</div>) : null}
                {stop.unloadOperations ? stop.unloadOperations.map(t => <div key={t}>{locContext.unload}: {t}</div>) : null}
            </div>
        </div>
    );
}