export class LanguageStore {
    private static STORAGE_KEY: string = "language";

    static get() {
        return window.localStorage.getItem(LanguageStore.STORAGE_KEY);
    }

    static getAndParseToDefault(): 'en' | 'fr' | 'nl' {
        const x = LanguageStore.get();
        if (x !== 'en' && x !== 'fr' && x !== 'nl') {
            return 'nl';
        } else {
            return x;
        }
    }

    static set(language: string) {
        window.localStorage.setItem(LanguageStore.STORAGE_KEY, language);
    }

    static remove(): void {
        window.localStorage.removeItem(LanguageStore.STORAGE_KEY);
    }
}