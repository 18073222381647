import React, { useState, useEffect, useContext } from 'react';
import { UserClient, IUserInfo } from '../gen/ApiClients';
import { TokenStore } from '../infrastructure/TokenStore';
import { useClient } from '../hooks/useClient';
import { RouterContext } from './RouterContext';
import * as fromRoutes from '../routes';
import { showSuccessToast } from '../components/common/toast/ToastNotification';
import { useLocalizationContext } from '../hooks/useLocalizationContext';

interface AuthenticationProps {
    hasToken: boolean,
    user: IUserInfo | undefined,
    login: (token: string) => void,
    logout: () => void,
}

const inititalState: AuthenticationProps = {
    hasToken: false,
    user: undefined,
    login: () => { },
    logout: () => { },
}

export const AuthContext = React.createContext<AuthenticationProps>(inititalState);

export const AuthProvider = (props: any) => {
    const [user, setUser] = useState<IUserInfo>();
    const client = useClient<UserClient>(UserClient);
    const routerContext = useContext(RouterContext);
    const locContext = useLocalizationContext();

    useEffect(() => {
        loadUser();
    }, 
    // eslint-disable-next-line
    []);

    const login = async (token: string) => {
        if (TokenStore.getToken() !== token) {
            TokenStore.setToken(token);
        }
        await loadUser();
    }

    const logout = () => {
        TokenStore.removeToken();
        setUser(undefined);
        routerContext.navigate(fromRoutes.LoginRoute);
        showSuccessToast(locContext.successfullyLoggedOut);
    }

    const loadUser = async () => {
        try {
            const user = await client.info();
            setUser(user);
        } catch (error) {
            console.log(error);
            setUser(undefined);
            TokenStore.removeToken();
        }
    }

    return (
        <AuthContext.Provider
            value={{ hasToken: TokenStore.getToken() ? true : false, user: user, login: login, logout: logout }}>
            {props.children}
        </AuthContext.Provider>
    );
}
