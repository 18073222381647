import React from 'react';
import { Document, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { IRevenuesQueryResult } from '../../../gen/ApiClients';
import { useLocalizationContext } from '../../../hooks/useLocalizationContext';
import { toDefaultFormat } from '../../../infrastructure/Utils';
import logo from './logo.jpg';

// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35,
        fontSize: 11,
    },
    header: {
        flexDirection: 'row',
        marginBottom: 10,
    },
    headerText: {
        marginLeft: 10,
        fontSize: 16,
    },
    subHeaderText: {
        marginLeft: 10,
        fontSize: 14,
    },
    driverHeader: {
        backgroundColor: '#EDEDED',
        width: '100%',
        padding: 5,
        fontSize: 14,
        marginBottom: 10,
        marginTop: 10,
    },
    perDayHeader: {
        backgroundColor: '#f7f7f7',
        width: '100%',
        padding: 5,
        fontSize: 12,
        marginBottom: 8,
        marginLeft: 8
        // marginTop: 8,
    },
    perAssignmentHeader: {
        backgroundColor: '#f7f7f7',
        width: '100%',
        padding: 5,
        fontSize: 12,
        marginBottom: 8,
        marginLeft: 12
        // marginTop: 8,
    },
    totals: {
        fontWeight: 'bold',
        backgroundColor: '#EDEDED',
        width: '100%',
        padding: 5,
        fontSize: 16,
        marginBottom: 10,
        marginTop: 10,
    },
    image: {
        width: 50,
        height: 50,
    },
    dfCol: {
        flexDirection: 'column',
    },
    dfRow: {
        flexDirection: 'row',
    },
    percent7: {
        width: '7%',
    },
    percent10: {
        width: '10%',
    },
    percent15: {
        width: '15%',
    },
    fileNumber: {
        width: '15%',
        marginLeft: '16px',
    },
    percent20: {
        width: '20%',
    },
    percent30: {
        width: '30%',
    },
    percent33: {
        width: '33%',
    },
    subTotalText: {
        fontWeight: 600,
        fontSize: 12,
        marginTop: 5,
    }
});

interface IProps {
    queryResult: IRevenuesQueryResult;
}
export const ExportDocument = ({ queryResult }: IProps) => {
    const locContext = useLocalizationContext();
    return (
        <Document>
            <Page size="A4" orientation="portrait" style={styles.page}>
                <View style={styles.header}>
                    <Image src={logo} style={styles.image} />
                    <View style={styles.dfCol}>
                        <Text style={styles.headerText}>{locContext.revenues}</Text>
                        <Text style={styles.subHeaderText}>{queryResult.servicePartnerName}</Text>
                        <Text style={styles.subHeaderText}>{toDefaultFormat(queryResult.afterDate)} - {toDefaultFormat(queryResult.beforeDate)}</Text>
                    </View>
                </View>
                {queryResult.values ?
                    queryResult.values.map(t =>
                        <View style={styles.dfCol}>
                            <View style={styles.driverHeader}>
                                <Text>{t.driverName} | &euro; {t.totalAmountExVat} ({locContext.exVat}) | &euro; {t.totalAmountInVat} ({locContext.inVat})</Text>
                            </View>
                            {t.values ? t.values.map(x =>
                                <View style={styles.dfCol}>
                                    <View style={styles.perDayHeader}>
                                        <Text>{locContext.date}: {toDefaultFormat(x.date)} | &euro; {x.amountExVat} ({locContext.exVat}) | &euro; {x.amountInVat} ({locContext.inVat})</Text>
                                    </View>
                                    {x.values ? x.values.map(y =>
                                        <View style={styles.dfCol}>
                                            <View style={styles.perAssignmentHeader}>
                                                <Text>{locContext.assignment}: {y.assignment} | &euro; {y.amountExVat} ({locContext.exVat}) | &euro; {y.amountInVat} ({locContext.inVat})</Text>
                                            </View>
                                            {y.values ? y.values.map(z =>
                                                <View style={styles.dfRow}>
                                                    {/* <Text style={styles.percent10}>{toDefaultFormat(z.date)}</Text> */}
                                                    {/* <Text style={styles.percent10}>{z.assignment}</Text> */}
                                                    <Text style={styles.fileNumber}>{z.fileNumber}</Text>
                                                    <Text style={styles.percent33}>{z.formattedAddress}</Text>
                                                    <Text style={styles.percent7}>{z.department}</Text>
                                                    <Text style={styles.percent15}>&euro; {z.amountExVat}</Text>
                                                    <Text style={styles.percent20}>{z.status}</Text>
                                                </View>
                                            )
                                                : undefined}
                                        </View>)
                                        : undefined}
                                </View>)
                                : undefined}
                            {/* {t.values ? t.values.map(x =>
                                <View style={styles.dfRow}>
                                    <Text style={styles.percent10}>{toDefaultFormat(x.date)}</Text>
                                    <Text style={styles.percent10}>{x.assignment}</Text> 
                                    <Text style={styles.percent10}>{x.fileNumber}</Text>
                                    <Text style={styles.percent33}>{x.formattedAddress}</Text>
                                    <Text style={styles.percent7}>{x.department}</Text>
                                    <Text style={styles.percent10}>&euro; {x.amountExVat}</Text>
                                    <Text style={styles.percent20}>{x.status}</Text>
                                </View>
                            ) : null} */}
                        </View>)
                    : null}
                <View style={styles.totals}>
                    <Text>{locContext.total}: &euro; {queryResult.totalAmountExVat} ({locContext.exVat}) | &euro; {queryResult.totalAmountInVat} ({locContext.inVat})</Text>
                </View>
                {/*  */}
            </Page>
        </Document>
    );
}