import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useCombineClassNames } from '../../../hooks/useCombineClassNames';
import { Popup, Responsive } from 'semantic-ui-react';
import './IconButton.scss';

export interface IIconButtonProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
    color?: 'success' | 'warning' | 'error' | 'gray';
    popupText?: string;
    size?: 'normal' | 'large' | 'very-large';
}

interface IProps extends IIconButtonProps {
    icon: IconProp;
}

export const IconButton = ({ icon, size = 'normal', color = undefined, popupText = undefined, ...rest }: IProps) => {
    const className = useCombineClassNames(`icon-button ${size} ${color}`, rest);
    const renderInner = () => {
        return (
            <button type="button" {...rest} className={className}>
                <FontAwesomeIcon icon={icon} />
            </button>
        );
    }
    if (popupText) {
        return (
            <div>
                <Responsive minWidth={Responsive.onlyTablet.minWidth}>
                    <Popup
                        trigger={renderInner()}
                        content={popupText}
                        basic
                    />
                </Responsive>
                <Responsive {...Responsive.onlyMobile}>
                    {renderInner()}
                </Responsive>
            </div>
        );
    }
    return renderInner();
}