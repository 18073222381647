import React from 'react';
import { Table } from 'semantic-ui-react';
import { useTablePage } from '../../../hooks/useTableSortAndPage';
import { BaseTableSort } from './BaseTableSort';
import { IHeader, IQueryResult, ISortAndPageProps, ISortProps } from '../../../infrastructure/Models';
import { PagingFooter } from './PagingFooter';
import './BaseTable.scss';

interface IProps<T, TQueryParams extends ISortAndPageProps> {
	canSort: boolean;
	headers: IHeader<T>[];
	uniqueIdentifier: keyof T;
	queryResult: IQueryResult<T> | undefined;
	params: TQueryParams;
	onSortOrPage: (params: TQueryParams) => void;
	fallbackSortProps?: ISortProps;
	className?: string;
	singleIdentifier: string;
	onClickRow?: (inst: T) => void;
	formatRow?: (inst: T) => string;
}

export function BaseTableSortAndPage<T, TQueryParams extends ISortAndPageProps>({
	queryResult,
	onSortOrPage,
	params,
	singleIdentifier,
	onClickRow,
	formatRow,
	...rest
}: IProps<T, TQueryParams>) {
	const [next, previous] = useTablePage<TQueryParams>();
	if (!queryResult || !queryResult.values) {
		return null;
	}

	function renderFooter(qr: IQueryResult<T>) {
		return (
			<Table.Footer>
				<Table.Row>
					<Table.HeaderCell colSpan={rest.headers.length}>
						<PagingFooter
							singleIdentifier={singleIdentifier}
							qr={queryResult ? queryResult : {}}
							onNext={() => {
								console.log('on click next');
								const nextParams = next(params);
								console.log(nextParams);
								onSortOrPage(nextParams);
								// onSortOrPage(next(params));
							}}
							onPrevious={() => onSortOrPage(previous(params))}
						/>
					</Table.HeaderCell>
				</Table.Row>
			</Table.Footer>
		);
	}

	return (
		<BaseTableSort<T, TQueryParams>
			{...rest}
			params={params}
			values={queryResult.values}
			onSort={t => onSortOrPage(t)}
			footer={renderFooter(queryResult)}
			onClickRow={onClickRow}
			formatRow={formatRow}
		/>
	);
}
