import React, { useContext } from 'react';
import { useLocalizationContext } from '../../../hooks/useLocalizationContext';
import { FormikProps, Formik, Form, FormikActions } from 'formik';
import { IOrderModel, IPackage, IExtra, IExtraProduct, OrderModel } from '../../../gen/ApiClients';
import { TextLinkButton } from '../../common/buttons/TextLinkButton';
import { PrimaryButton } from '../../common/buttons/PrimaryButton';
import { SelectField } from '../../common/forms/SelectField';
import { FilterDataContext } from '../../../context/FilterDataContext';
import { IOption } from '../../../infrastructure/Models';
import { setFieldTouched } from '../../common/forms/FormsUtils';
import { TextInputField } from '../../common/forms/TextInputField';
import { clearNullOrUndefinedValues } from '../../../infrastructure/Utils';
import { Responsive } from 'semantic-ui-react';
import * as yup from 'yup';
import './OrderClothingForm.scss';

interface IProps {
    model: IOrderModel;
    setModel: (model: IOrderModel) => void;
    onSubmit: (model: IOrderModel) => void;
    onCancel: () => void;
    addExtra: () => void;
    addPackage: () => void;
    deletePackage: (pack: IPackage) => void;
    deleteExtra: (extra: IExtra) => void;
    products: IExtraProduct[];
}

export const MainForm = ({ onSubmit, onCancel, addExtra, addPackage, deleteExtra, deletePackage, products, model, setModel }: IProps) => {
    const context = useContext(FilterDataContext);
    const options = context.allFilterableDrivers.map<IOption<string>>(t => ({ key: t.id ? t.id : '', value: t.id ? t.id : '', text: t.value ? t.value : '', }));
    const locContext = useLocalizationContext();
    const validateSchema = yup.object<IOrderModel>().shape<IOrderModel>({
        driverId: yup
            .string()
            .required(locContext.validateSelectDriver),
        mobilePhoneDriver: yup
            .string()
            .required(locContext.fillIn),
    });
    const onClickSubmit = async (props: FormikProps<IOrderModel>, e: any) => {
        e.persist();
        const isValid = await validateSchema.isValid(props.values);
        if (!isValid) {
            e.preventDefault();
        }
        setFieldTouched<IOrderModel>('driverId', props);
        setFieldTouched<IOrderModel>('mobilePhoneDriver', props);
    }
    const handleSubmit = async (values: IOrderModel, actions: FormikActions<IOrderModel>) => {
        if ((!values.extras || values.extras.length === 0) && (!values.starterPacks || values.starterPacks.length === 0)) {
        } else {
            await onSubmit({ ...model, driverId: values.driverId, mobilePhoneDriver: values.mobilePhoneDriver });
        }        
        actions.setSubmitting(false);
    }

    const calculatePrice = (extra: IExtra): number => {
        const x = products.find(t => t.description === extra.description);
        if (x) {
            return x.price && extra.quantity ? x.price * extra.quantity : 0;
        }
        return 0;
    }

    const calculateTotalPrice = (values: IOrderModel) => {
        const x = values.extras && values.extras.length > 0 ? values.extras.map<number>(t => calculatePrice(t)).reduce((sum, current) => sum + current) : 0;
        console.log(x);
        const y = values.starterPacks && values.starterPacks.length > 0 ? values.starterPacks.map<number>(t => (t.quantity ? t.quantity : 1) * 30).reduce((sum, current) => sum + current) : 0;
        console.log(y);
        return x + y;
    }

    return (
        <div>
            <Formik<IOrderModel>
                initialValues={clearNullOrUndefinedValues<IOrderModel>(model, OrderModel)}
                isInitialValid={true}
                validationSchema={validateSchema}
                onSubmit={handleSubmit}>
                {(props) => (
                    <Form>
                        <div className="df-col ocf-container pos-rel">
                            <div className="title">{locContext.orderClothing}</div>
                            <SelectField<IOrderModel> options={options} label={locContext.driver} xName="driverId" />
                            <TextInputField<IOrderModel> label={locContext.driverMobilePhone} xName="mobilePhoneDriver" placeholder={locContext.driverMobilePhone} />
                            <TextLinkButton className="font-bold add-button" onClick={() => { addExtra(); setModel(props.values); }}>+ {locContext.addExtra}</TextLinkButton>
                            {model.extras && model.extras.length > 0 ?
                                <div className="df-col">
                                    <div className="header-text">{locContext.extras}</div>
                                    {model.extras.map(t =>
                                        <div key={model.extras ? model.extras.indexOf(t) : ''} className="df-row jc-sb single-extra">
                                            <div className="df-col">
                                                {t.quantity} x {t.description} ({locContext.size}: {t.size}, {locContext.price}: &euro; {calculatePrice(t)})
                                            </div>
                                            <TextLinkButton gray className="lo-button" onClick={() => deleteExtra(t)}>{locContext.delete}</TextLinkButton>
                                        </div>)}
                                </div>
                                : null}
                            <TextLinkButton className="font-bold add-button" onClick={() => { addPackage(); setModel(props.values) }}>+ {locContext.addStartersPack}</TextLinkButton>
                            {model.starterPacks && model.starterPacks.length > 0 ?
                                <div className="df-col">
                                    <div className="header-text">{locContext.startersPackages}</div>
                                    {model.starterPacks.map(t =>
                                        <div key={model.starterPacks ? model.starterPacks.indexOf(t) : ''} className="df-row jc-sb single-package">
                                            <div className="df-col">
                                                <div>{t.quantity} x {locContext.startersPackage} ({locContext.price}: &euro; {30.00 * (t.quantity ? t.quantity : 1)})</div>
                                                <div>{locContext.sizePolo}: {t.sizePolo}</div>
                                                <div>{locContext.sizeSweatShirt}: {t.sizeSweatShirt}</div>
                                                <div>{locContext.sizeTShirt}: {t.sizeTShirt}</div>
                                            </div>
                                            <TextLinkButton gray className="lo-button" onClick={() => deletePackage(t)}>{locContext.delete}</TextLinkButton>
                                        </div>)}
                                </div>
                                : null}
                            <div className="order-clothing-total">{locContext.total}: &euro; {calculateTotalPrice(model)}</div>
                            <Responsive minWidth={Responsive.onlyTablet.minWidth}>
                                <div className="df-row-ac jc-e buttons-container">
                                    <div className="df-row-ac">
                                        <TextLinkButton className="cancel-button" onClick={() => onCancel()}>{locContext.cancel}</TextLinkButton>
                                        <PrimaryButton disabled={props.isSubmitting} showLoader type="submit" onClick={(e) => onClickSubmit(props, e)}>{locContext.requestClothing}</PrimaryButton>
                                    </div>
                                </div>
                            </Responsive>
                            <Responsive {...Responsive.onlyMobile}>
                                <div className="df-col buttons-container">
                                    <PrimaryButton disabled={props.isSubmitting} showLoader type="submit" onClick={(e) => onClickSubmit(props, e)}>{locContext.requestClothing}</PrimaryButton>
                                    <TextLinkButton className="cancel-button" onClick={() => onCancel()}>{locContext.cancel}</TextLinkButton>
                                </div>
                            </Responsive>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
}
