import React from 'react';
import { Formik, Form, FormikProps } from 'formik';
import { useLocalizationContext } from '../../hooks/useLocalizationContext';
import { TextareaField } from '../common/forms/TextareaField';
import { TextLinkButton } from '../common/buttons/TextLinkButton';
import { PrimaryButton } from '../common/buttons/PrimaryButton';
import './DisputeRevenueForm.scss';
import * as yup from 'yup';
import { setFieldTouched } from '../common/forms/FormsUtils';
import { Responsive } from 'semantic-ui-react';

interface IProps {
    fileNumber: string;
    onSend: (message: string) => Promise<any>;
    onCancel: () => void;
}

interface IModel {
    message: string;
}

export const DisputeRevenueForm = ({ fileNumber, onSend, onCancel }: IProps) => {
    const locContext = useLocalizationContext();
    const validateSchema = yup.object<IModel>().shape<IModel>({
        message: yup
            .string()
            .required(locContext.validateDisputeMessage),
    });

    const onClickSubmit = async (props: FormikProps<IModel>, e: any) => {
        props.setSubmitting(true);
        e.preventDefault();
        setFieldTouched<IModel>('message', props);
        if (props.isValid) {
            await onSend(props.values.message);
        }
        props.setSubmitting(false);
    }

    return (
        <div>
            <Formik<IModel>
                initialValues={{ message: '' }}
                isInitialValid={true}
                validationSchema={validateSchema}
                onSubmit={() => { }}>
                {(props) => (
                    <Form>
                        <div className="df-col dd-container">
                            <div className="dd-title">{locContext.disputeData}</div>
                            <div className="dd-label-file-number">{locContext.fileNumber}</div>
                            <div className="dd-file-number">{fileNumber}</div>
                            <TextareaField<IModel> label={locContext.labelDisputeMessage} xName="message" />
                            <Responsive minWidth={Responsive.onlyTablet.minWidth}>
                                <div className="df-row-ac jc-e">
                                    <div className="df-row-ac">
                                        <TextLinkButton className="cancel-button" onClick={() => onCancel()}>{locContext.cancel}</TextLinkButton>
                                        <PrimaryButton disabled={props.isSubmitting} showLoader type="submit" onClick={(e) => onClickSubmit(props, e)}>{locContext.send}</PrimaryButton>
                                    </div>
                                </div>
                            </Responsive>
                            <Responsive {...Responsive.onlyMobile}>
                                <div className="df-col">
                                    <PrimaryButton disabled={props.isSubmitting} showLoader type="submit" onClick={(e) => onClickSubmit(props, e)}>{locContext.send}</PrimaryButton>
                                    <TextLinkButton className="cancel-button" onClick={() => onCancel()}>{locContext.cancel}</TextLinkButton>
                                </div>
                            </Responsive>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
}
