import React, { useState } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import { empty } from "../infrastructure/Utils";

export interface IRouterProps {
  history: any;
  location: any;
  match: any;
  activeRoute: string;
  navigate: (str: string) => void;
  goBack: () => void;
}

export const RouterContext = React.createContext<IRouterProps>({ history: {}, location: {}, match: {}, activeRoute: '', navigate: empty, goBack: empty });

export const BrowserRouterProvider = ({ children }: any) => {
  const [activeRoute, setActiveRoute] = useState<string>('');
  const navigate = (str: string, routeProps: IRouterProps) => {
    routeProps.history.push(str);
  }
  const goBack = (routeProps: IRouterProps) => {
    routeProps.history.goBack();
  }
  return (
    <BrowserRouter>
      <Route>
        {(routeProps: IRouterProps) => {
          if (activeRoute === '' || activeRoute === null || activeRoute === undefined) {
            setActiveRoute(routeProps.location.pathname);
          }
          routeProps.history.listen((location: any, action: any) => {
            setActiveRoute(location.pathname);
          });
          return (
            <RouterContext.Provider
              value={{
                history: routeProps.history,
                location: routeProps.location,
                match: routeProps.match,
                activeRoute: activeRoute,
                navigate: (str: string) => navigate(str, routeProps),
                goBack: () => goBack(routeProps),
              }}> 
              {children}
            </RouterContext.Provider>
          );
        }
        }
      </Route>
    </BrowserRouter>
  );
}