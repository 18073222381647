import React, { useState } from 'react';
import { Table } from 'semantic-ui-react';
import { JohnDoeAvatar } from '../common/johnDoeAvatar/JohnDoeAvatar';
import { ILeaveOfAbsencesQueryResult, ILeaveOfAbsenceSummary } from '../../gen/ApiClients';
import { DateRange } from 'moment-range';
import moment from 'moment';
import './LeaveOfAbsencesTable.scss';

interface IProps {
    queryResult: ILeaveOfAbsencesQueryResult;
    onViewDetail: (loas: ILeaveOfAbsenceSummary[]) => void;
    onAdd: (date: Date, driverId: string) => void;
}

export const LeaveOfAbsencesTable = ({ queryResult, onViewDetail, onAdd }: IProps) => {
    if (queryResult.afterDate === undefined || queryResult.beforeDate === undefined || !queryResult.values) {
        return null;
    }
    const dates = Array.from(new DateRange(queryResult.afterDate, queryResult.beforeDate).by('days'));

    const renderSingleCellBis = (m: moment.Moment, vals: ILeaveOfAbsenceSummary[], driverId: string) => {
        const x = vals.filter(x => x.date !== undefined && x.date.getFullYear() === m.year() && moment(x.date).dayOfYear() === m.dayOfYear());
        if (x.length > 0) {
            if (x.length === 1) {
                const y = x[0];
                const content = y.isWholeDay ? 'X' : y.hours ? y.hours.toString() : 'E';
                return <LoaCell onClick={() => onViewDetail(x)} content={content} color={y.isWholeDay ? 'error' : 'warning'} />
            } else {
                const content = x.flatMap(t => t.hours ? t.hours : 0).reduce((sum, current) => sum + current);
                return <LoaCell onClick={() => onViewDetail(x)} content={content.toString()} color="warning" />
            }
        } else {
            return <EmptyCell onClick={() => onAdd(m.toDate(), driverId)} />;
        }
    }

    return (
        <div className="loa-table-container">
            <Table className="loa-table"
                celled
                sortable={false}
                selectable={false}
                collapsing={false}
                definition={false}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell
                            className='no-sort driver-header'>
                            Chauffeurs
                        </Table.HeaderCell>
                        {dates.map(t => <Table.HeaderCell key={t.format('DD/MM/YYYY')}
                            className='no-sort date-header'>
                            <div className="df-col-ac">
                                <div>{t.format('ddd')}</div>
                                <div className="date-value">{t.date()}</div>
                            </div>
                        </Table.HeaderCell>)}
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {queryResult.values.map(t =>
                        <Table.Row key={t.driverId}>
                            <Table.Cell>
                                <div className="df-row-ac driver-value">
                                    <JohnDoeAvatar width={29} height={29} />
                                    <div className="driver-name">{t.driverName}</div>
                                </div>
                            </Table.Cell>
                            {dates.map(y =>
                                    <Table.Cell className="loa-value" key={y.format('DD/MM/YYYY')}>
                                        {renderSingleCellBis(y, t.values ? t.values : [], t.driverId ? t.driverId : '')}
                                    </Table.Cell>)}
                        </Table.Row>)}
                </Table.Body>
            </Table>
        </div>
    );
}

interface ILoaCellProps {
    content: string;
    onClick: () => void;
    color: 'error' | 'warning';
}

const LoaCell = ({ content, onClick, color }: ILoaCellProps) => {
    return (
        <div className={`df-row-ac jc-c stretch-ver outer-fill-cell`} onClick={() => onClick()}>
            <div className={`df-col-ac jc-c inner-loa-fill-cell stretch-hor stretch-ver ${color}`}>
                {content}
            </div>
        </div>
    );
}

interface IEmptyCellProps{
    onClick: () => void;
}

const EmptyCell = ({ onClick }: IEmptyCellProps) => {
    const [isHover, setisHover] = useState<boolean>(false);
    return (
        <div className="df-row-ac jc-c stretch-ver outer-fill-cell" onClick={() => onClick()} onMouseEnter={() => setisHover(true)} onMouseLeave={() => setisHover(false)}>
            <div className="df-col-ac jc-c inner-empty-fill-cell stretch-hor stretch-ver">
                {isHover ? '+' : ''}
            </div>
        </div>
    );
}
