import { useState } from 'react';
import { ISettlementProposal, ISettlementProposalQueryParams } from '../gen/ApiClients';
import { nameof } from '../infrastructure/Utils';
import { SettlementProposalQueryParamsContext } from './SettlementProposalsQueryParamsContext';
import React from 'react';

const DefaultParams: ISettlementProposalQueryParams = {
	pageIndex: 1,
	pageSize: 10,
	sortDirection: 'descending',
	sortProperty: nameof<ISettlementProposal>('postingDate'),
};

export const SettlementProposalQueryParamsProvider = ({ children }: any) => {
	const [params, setParams] = useState<ISettlementProposalQueryParams>(DefaultParams);

	return (
		<SettlementProposalQueryParamsContext.Provider
			value={{
				params: params,
				setParams: setParams,
			}}>
			{children}
		</SettlementProposalQueryParamsContext.Provider>
	);
};
