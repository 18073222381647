import React from "react";
import { useLocalizationContext } from "../../../hooks/useLocalizationContext";
import { PrimaryButton } from "../buttons/PrimaryButton";
import { IQueryResult } from "../../../infrastructure/Models";
import "./PagingFooter.scss";
import { useCombineClassNames } from "../../../hooks/useCombineClassNames";

interface IProps<T> {
  qr: IQueryResult<T>;
  onNext: () => void;
  onPrevious: () => void;
  singleIdentifier: string;
  className?: string;
}

export function PagingFooter<T>({
  qr,
  onNext,
  onPrevious,
  singleIdentifier,
  ...rest
}: IProps<T>) {
  const locContext = useLocalizationContext();
  return (
    <div className={useCombineClassNames("df-row-ac jc-sb bt-footer", rest)}>
      <div className="bt-footer-page-info">
        {locContext.formatString(
          "pagingTable",
          singleIdentifier,
          qr.currentFrom,
          qr.currentTo,
          qr.totals
        )}
      </div>
      <div className="df-row-ac">
        <PrimaryButton
          small
          outline
          className="bt-previous-button"
          disabled={!qr.hasPreviousPage}
          onClick={onPrevious}
        >
          {locContext.previous}
        </PrimaryButton>
        <PrimaryButton
          small
          outline
          disabled={!qr.hasNextPage}
          onClick={onNext}
        >
          {locContext.next}
        </PrimaryButton>
      </div>
    </div>
  );
}
