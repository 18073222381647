import React from 'react';
import { IFieldGroupProps, FieldGroup } from './FieldGroup';
import { Field } from 'formik';
import { IOption } from '../../../infrastructure/Models';
import { CustomDropDown } from '../dropdown/CustomDropdown';
import './SelectField.scss';
import { useLocalizationContext } from '../../../hooks/useLocalizationContext';

interface IProps<T> extends IFieldGroupProps<T> {
    options: IOption<string>[];
    placeholder?: string;
}

export function SelectField<T>({ options, placeholder, ...props }: IProps<T>) {
    const locContext = useLocalizationContext();
    const setValue = (fieldProps: any, val: string) => {
        fieldProps.form.setFieldValue(fieldProps.field.name, val, true);
    }
    const getValue = (fieldProps: any) => {
        return fieldProps.form.values[fieldProps.field.name];
    }

    return (
        <FieldGroup {...props}>
            <Field name={props.xName} component={(fieldProps: any) =>
                <CustomDropDown<string>
                    onClear={() => setValue(fieldProps, '')}
                    placeholder={placeholder ? placeholder : locContext.select}
                    options={options}
                    onSelect={(val) => setValue(fieldProps, val)}
                    selected={getValue(fieldProps)} /> } />
        </FieldGroup>
    );
}