import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useEffect, useState } from 'react';
import { Card, Responsive } from 'semantic-ui-react';
import { ModalContext } from '../../context/ModalContext';
import { RouterContext } from '../../context/RouterContext';
import { WrapperFullScreenLoadContext } from '../../context/WrapperFullScreenLoadContext';
import { WrapperSearchContext } from '../../context/WrapperSearchTextContext';
import { BuildClient, DisputeModel, IRevenueDetail, RevenueDetail, RevenuesClient } from '../../gen/ApiClients';
import { useClient, useDownloadClient } from '../../hooks/useClient';
import { useLocalizationContext } from '../../hooks/useLocalizationContext';
import { toDefaultFormat, tryCatchWithLoading } from '../../infrastructure/Utils';
import { IconButton } from '../common/buttons/IconButton';
import { JohnDoeAvatar } from '../common/johnDoeAvatar/JohnDoeAvatar';
import { showSuccessToast } from '../common/toast/ToastNotification';
import { DisputeRevenueForm } from './DisputeRevenueForm';
import './Revenue.scss';
import { StopsViewComponent } from './StopsViewComponent';
import React from 'react';

export const Revenue = ({ match }: any) => {
	const locContext = useLocalizationContext();
	const shipmentId = match.params.shipmentId;
	const client = useClient(RevenuesClient);
	const routerContext = useContext(RouterContext);
	const downloadClient = useDownloadClient(routerContext);
	const [detail, setDetail] = useState<RevenueDetail>();
	const modalContext = useContext(ModalContext);
	const loaderContext = useContext(WrapperFullScreenLoadContext);
	useContext(WrapperSearchContext).hideAndShowGoBackButton();
	const buildClient = useClient(BuildClient);

	useEffect(
		() => {
			loadDetail();
		},
		// eslint-disable-next-line
		[shipmentId]
	);

	const loadDetail = async () => {
		if (shipmentId) {
			const t = await tryCatchWithLoading(client.detail(shipmentId), loaderContext.setLoading, locContext.serverError);
			setDetail(t);
		}
	};

	const dispute = (detail: IRevenueDetail) => {
		const fileNumber = detail.fileNumber ? detail.fileNumber : 'No filenumber found...';
		modalContext.open(
			<DisputeRevenueForm
				fileNumber={fileNumber}
				onSend={async message => {
					await client.dispute(fileNumber, new DisputeModel({ message: message }));
					showSuccessToast(locContext.disputeFormSubmitted);
					modalContext.close();
				}}
				onCancel={() => modalContext.close()}
			/>,
			false
		);
	};

	const downloadSpc = async (inst: IRevenueDetail) => {
		loaderContext.setLoading(true);
		const baseUrl = await buildClient.url();
		const url = `${baseUrl}/api/Revenues/${inst.fileNumber}/downloadServicePartnerConfirmation`;
		await downloadClient.download(url, `SPC_${inst.fileNumber}.pdf`, () => loaderContext.setLoading(false));
	};

	if (detail === undefined) {
		return null;
	}

	return (
		<Card className='revenue-detail-card df-col'>
			<div className='df-row df-col-reverse-if-mobile jc-sb'>
				<div className='df-row-ac'>
					<Responsive minWidth={Responsive.onlyTablet.minWidth}>
						<JohnDoeAvatar />
					</Responsive>
					<Responsive {...Responsive.onlyMobile}>
						<JohnDoeAvatar
							width={30}
							height={30}
						/>
					</Responsive>
					<div className='driver-name'>{detail.driverName}</div>
				</div>
				<div className='df-row-ac jc-sb status-and-buttons-container'>
					{detail.isTrackAndTraceAvailable ? (
						<div className={`status df-row-ac confirmed`}>
							<FontAwesomeIcon icon={['fas', 'check']} />
							<div className='status-text'>{detail.status}</div>
						</div>
					) : (
						<div className={`status df-row-ac`}>
							<div className='status-text'>{detail.status}</div>
						</div>
					)}
					<div className='df-row-ac'>
						<IconButton
							size='very-large'
							icon={['far', 'comments']}
							popupText={locContext.dispute}
							className='dispute-button'
							onClick={() => dispute(detail)}
							color='success'
						/>
						<IconButton
							size='very-large'
							icon={['fas', 'file-signature']}
							className='download-button'
							color='success'
							popupText={locContext.download}
							onClick={() => downloadSpc(detail)}
						/>
					</div>
				</div>
			</div>
			<div className='label'>{locContext.date}</div>
			<div className='info'>{toDefaultFormat(detail.date)}</div>
			<div className='df-row-ac'>
				<div className='df-col assignment-container'>
					<div className='label'>{locContext.assignment}</div>
					<div className='info'>{detail.subDepartment}</div>
				</div>
				<div className='df-col'>
					<div className='label'>{locContext.fileNumber}</div>
					<div className='info'>{detail.fileNumber}</div>
				</div>
			</div>
			<div className='label'>{locContext.vehicle}</div>
			<div className='info'>{detail.department}</div>
			<StopsViewComponent
				firstStop={detail.firstStop}
				extraStops={detail.extraStops}
				lastStop={detail.lastStop}
			/>
			<div className='label'>{locContext.amountExVat}</div>
			<div className='info'>&euro; {detail.amount}</div>
			<div className='df-row'>
				{detail.revenues
					? detail.revenues.map(t => (
							<div
								className='df-col single-price-calc'
								key={`${t.description}-${t.amount}`}>
								<div className='label'>{t.description}</div>
								<div className='info'>&euro; {t.amount}</div>
							</div>
					  ))
					: null}
			</div>
			<div className='label'>{locContext.route}</div>
			<div className='info'>{detail.totalDistance} km</div>
		</Card>
	);
};
