import React, { useState } from 'react';
import { useEnterKeyDown } from '../../../hooks/useEnterKeyDown';
import { useCombineClassNames } from '../../../hooks/useCombineClassNames';
import { IconButton } from '../buttons/IconButton';
import './Searchbox.scss';

interface IProps extends React.HTMLProps<HTMLDivElement> {
    onTextChanged?: (str: string) => void;
    onSearch: (str: string) => void;
    externalProps?: IExternalSearchboxProps;
    large?: boolean;    
}

interface IExternalSearchboxProps {
    text: string;
    setText: (str: string) => void;
}

export const Searchbox = ({ placeholder, onTextChanged, onSearch, externalProps, large = false, ...rest }: IProps) => {
    const [text, setText] = useState<string>('');
    const search = (e: any) => {
        if (e !== null) {
            e.preventDefault();
        }
        if (onSearch) {
            onSearch(externalProps ? externalProps.text : text);
        }
    }
    const handleKeyDown = useEnterKeyDown(search);
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        handleChangeWithStr(e.target.value);
    }
    const handleChangeWithStr = (str: string) => {
        if (externalProps) {
            externalProps.setText(str);
        } else {
            setText(str);
        }
        if (onTextChanged) {
            onTextChanged(str);
        }
    }

    const clear = () => {
        handleChangeWithStr('');
        if (onSearch){
            onSearch('');
        }
    }

    const isFiltered = externalProps ? externalProps.text : text;

    return (
        <div {...rest} className={useCombineClassNames(`search-box df-row-ac ${large ? ' large' : ''}`, rest)}>
            <IconButton icon="search" className="search-button" onClick={search}></IconButton>
            <input type="text" placeholder={placeholder} onChange={handleChange} onKeyDown={handleKeyDown} value={externalProps ? externalProps.text : text}></input>
            {isFiltered ?
                <IconButton
                    className="clear-button"
                    icon={['fas', 'times']}
                    onClick={() => clear()} />
                : null}
        </div>
    );
}