import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Card } from 'semantic-ui-react';
import { ModalContext } from '../../context/ModalContext';
import { WrapperFullScreenLoadContext } from '../../context/WrapperFullScreenLoadContext';
import { WrapperSearchContext } from '../../context/WrapperSearchTextContext';
import { IRevenuesQueryResult, RevenuesClient } from '../../gen/ApiClients';
import { useClient } from '../../hooks/useClient';
import { useLocalizationContext } from '../../hooks/useLocalizationContext';
import { tryCatchWithLoading } from '../../infrastructure/Utils';
import * as routes from '../../routes';
import { InformationIconButton } from '../common/buttons/InformationIconButton';
import { PrimaryButton } from '../common/buttons/PrimaryButton';
import { RevenuePerDriver } from '../revenues/RevenuePerDriver';
import { RevenuesSummary } from '../revenues/RevenuesSummary';
import './Dashboard.scss';
import { InfoTodoCard } from './InfoTodoCard';

export const Dashboard = () => {
    const locContext = useLocalizationContext();
    const [queryResult, setQueryResult] = useState<IRevenuesQueryResult>({});
    const client = useClient(RevenuesClient);
    useContext(WrapperSearchContext).hide();
    const loaderContext = useContext(WrapperFullScreenLoadContext);
    const modalContext = useContext(ModalContext);

    useEffect(() => {
        load();
    }, 
    // eslint-disable-next-line
    []);

    const load = async () => {
        setQueryResult(await tryCatchWithLoading(client.transportsThisMonth(), loaderContext.setLoading, locContext.serverError));
    }

    const showTotals = () => {
        if (!queryResult.values) {
            return;
        }
        modalContext.open(
            <RevenuesSummary
                afterDate={queryResult.afterDate}
                beforeDate={queryResult.beforeDate}
                drivers={queryResult.values.map(t => t.driverName ? t.driverName : '')}
                amountExVat={queryResult.totalAmountExVat}
                amountInVat={queryResult.totalAmountInVat}
                vatAmount={queryResult.totalVatAmount}
                previousMonthAmountExVat={queryResult.previousMonthTotalAmountExVat}
                percentageChangeAmountExVat={queryResult.percentageChangeTotalAmountExVat} />
            , true);
    }

    return (
        <div className="df-col dashboard-container">
            <div className="df-row df-col-reverse-if-mobile">
                <InfoTodoCard className="info-card" />
                {/* <WeatherCard className="weather-card" /> */}
            </div>
            <Card className="confirmed-transports-card">
                <div className="df-col stretch-ver">
                    <div className="df-row-ac jc-sb transports-header">
                        <div className="df-row-ab">
                            <div className="revenues-title df-row">{locContext.yourRevenuesThisMonth}: &euro; {queryResult.totalAmountExVat}</div>
                            {/* {renderChange(queryResult.percentageChangeTotalAmountExVat)} */}
                            <InformationIconButton className="totals-button" onClick={() => showTotals()} />
                        </div>
                        <div className="df-row-ac">
                            <Link to={routes.RevenuesRoute}>
                                <PrimaryButton small outline>{locContext.moreInfo}</PrimaryButton>
                            </Link>
                        </div>
                    </div>
                    {queryResult.values === undefined || queryResult.values.length === 0 ? <div className="no-revenues">{locContext.noRevenues}</div> : null}
                    {queryResult.values ?
                        queryResult.values.map(t => <RevenuePerDriver
                            // showStatus={false}
                            key={t.driverId}
                            value={t}
                            afterDate={queryResult.afterDate}
                            beforeDate={queryResult.beforeDate} />)
                        : null}
                </div>
            </Card>
        </div>
    );
}