import React, { useContext } from 'react'
import { RouteProps, Route, Redirect } from 'react-router'
import * as fromRoutes from '../routes';
import { AuthContext } from '../context/AuthContext';

export const ProtectedRoute : any = ( { component: Component, ...rest}: {component: React.ComponentType<RouteProps>} )  => {
  const authContext = useContext(AuthContext);
  return (
    <Route render={props => authContext.hasToken ? <Component {...props} /> : <Redirect to={fromRoutes.LoginRoute} />} {...rest} />
  );
}