import React from 'react';
import { CenterEveryting } from './CenterEveryting';
import { Loader } from './Loader';
import './FullScreenLoader.scss';

export const FullScreenLoader = () => {
    return (
        <CenterEveryting className="full-screen-loader">
            <Loader />
        </CenterEveryting>
    );
}