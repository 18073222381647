import React, { useContext } from 'react';
import { TransportStatus, IRevenuesQueryParams } from '../../gen/ApiClients';
import { useLocalizationContext } from '../../hooks/useLocalizationContext';
import { enumToOptions } from '../../infrastructure/Utils';
import { FilterBar } from '../common/filter/FilterBar';
import { EnumListFilterItem } from '../common/filter/EnumListFilterItem';
import { ListFilterItem } from '../common/filter/ListFilterItem';
import { IOption } from '../../infrastructure/Models';
import { FilterDataContext } from '../../context/FilterDataContext';

interface IProps {
	params: IRevenuesQueryParams;
	onFilter: (params: IRevenuesQueryParams) => void;
}

function isFiltered(pars: IRevenuesQueryParams) {
	if (!pars) {
		return false;
	}
	if (pars.statuses && pars.statuses.length > 0) {
		return true;
	}
	if (pars.driverIds && pars.driverIds.length > 0) {
		return true;
	}
	return false;
}

export const FilterRevenues = ({ params, onFilter }: IProps) => {
	const locContext = useLocalizationContext();
	const context = useContext(FilterDataContext);

	const translations = new Map<TransportStatus, string>([
		[TransportStatus._SendToApp, locContext.sendToApp],
		[TransportStatus._ConfirmedByDriver, locContext.confirmedByDriver],
		[TransportStatus._InProgress, locContext.inProgress],
		[TransportStatus._Outsourced, locContext.outsourced],
		[TransportStatus._Loaded, locContext.loaded],
		[TransportStatus._Departed, locContext.departed],
		[TransportStatus._Unloaded, locContext.unloaded],
		[TransportStatus._Processed, locContext.processedDrivers],
		[TransportStatus._Canceled, locContext.canceled],
		[TransportStatus._DeadFreight, locContext.deadFreight],
		[TransportStatus._SentShipmentChanged, 'Sent shipment changed'],
	]);
	const statusOptions = enumToOptions<string>(TransportStatus, translations);

	const handleClear = () => {
		onFilter({ ...params, statuses: [], driverIds: [] });
	};
	return (
		<FilterBar
			onClear={() => handleClear()}
			isFiltered={isFiltered(params)}>
			<div className='df-row-ac df-col-if-mobile'>
				<EnumListFilterItem
					icon={['fas', 'info']}
					label={locContext.status}
					options={statusOptions}
					selection={params.statuses ? params.statuses : []}
					onFilter={sel => onFilter({ ...params, statuses: sel })}
				/>
				<ListFilterItem<string>
					icon={['fas', 'users']}
					label={locContext.driver}
					options={context.allFilterableDrivers.map<IOption<string>>(t => ({ key: t.id ? t.id : '', value: t.id ? t.id : '', text: t.value ? t.value : '' }))}
					selection={params.driverIds ? params.driverIds : []}
					onFilter={val => onFilter({ ...params, driverIds: val })}
				/>
			</div>
		</FilterBar>
	);
};
