import React, { useState, useEffect, useContext } from 'react';
import { Card, Divider, Responsive } from 'semantic-ui-react';
import { IFormSummary, IFormSummaryQueryResult, FormsClient, FormsQueryParams, IFormsQueryParams, OrderModel } from '../../gen/ApiClients';
import { toDateAndTimeFormat, toDefaultFormat, tryCatchWithLoading, tryCatch } from '../../infrastructure/Utils';
import { useLocalizationContext } from '../../hooks/useLocalizationContext';
import { useClient } from '../../hooks/useClient';
import { BaseTableSortAndPage } from '../common/table/BaseTableSortAndPage';
import { createHeader, createSortableHeader } from '../common/table/TableUtils';
import { WrapperSearchContext } from '../../context/WrapperSearchTextContext';
import { WrapperFullScreenLoadContext } from '../../context/WrapperFullScreenLoadContext';
import { FilterForms } from './FilterForms';
import { FormsQueryParamsContext } from '../../context/FormsQueryParamsContext';
import { useTrigger } from '../../hooks/useTrigger';
import { PrimaryButton } from '../common/buttons/PrimaryButton';
import { ModalContext } from '../../context/ModalContext';
import { OrderClothingForm } from './orderClothing/OrderClothingForm';
import { FormDetail } from './FormDetail';
import './Forms.scss';
import { showSuccessToast } from '../common/toast/ToastNotification';
import { PagingFooter } from '../common/table/PagingFooter';

export const Forms = () => {
	const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
	const locContext = useLocalizationContext();
	const [queryResult, setQueryResult] = useState<IFormSummaryQueryResult>();
	const { params, setParams } = useContext(FormsQueryParamsContext);
	const client = useClient(FormsClient);
	const wrapperLoaderContext = useContext(WrapperFullScreenLoadContext);
	const [trigger, hitTrigger] = useTrigger();
	const modalContext = useContext(ModalContext);
	useContext(WrapperSearchContext).init(`${locContext.searchOnTitleOrDescription}...`, params.searchString, (str: string) => {
		setParams({ ...params, searchString: str, pageIndex: 1 });
	});

	useEffect(
		() => {
			load();
		},
		// eslint-disable-next-line
		[params, trigger]
	);

	const load = async () => {
		setQueryResult(await tryCatchWithLoading(client.query(new FormsQueryParams(params)), wrapperLoaderContext.setLoading, locContext.serverError));
	};

	const headers = [
		createSortableHeader<IFormSummary>(locContext.date, 'createdDate', t => toDateAndTimeFormat(t.createdDate)),
		createSortableHeader<IFormSummary>(locContext.driver, 'driverName', t => t.driverName),
		createHeader<IFormSummary>(locContext.description, t => t.formatted),
		createHeader<IFormSummary>(locContext.price, t => <div>&euro; {t.totalPrice}</div>),
	];

	const viewDetail = async (inst: IFormSummary) => {
		if (inst.id === undefined) {
			return;
		}
		const detail = await client.single(inst.id);
		modalContext.open(<FormDetail detail={detail} />, true);
	};

	const orderClothing = () => {
		modalContext.open(
			<OrderClothingForm
				onSubmit={async val => {
					await tryCatch(client.addOrder(new OrderModel(val)), locContext.serverError);
					hitTrigger();
					showSuccessToast(locContext.formSubmitted);
					modalContext.close();
				}}
				onCancel={() => modalContext.close()}
			/>,
			false
		);
	};

	return (
		<Card className='forms-card'>
			<div className='df-col stretch-ver'>
				<div className='df-row-ac jc-sb forms-header'>
					<div className='df-row-ac'>
						<PrimaryButton
							small
							onClick={() => orderClothing()}>
							+ {locContext.orderClothing}
						</PrimaryButton>
					</div>
					<div className='df-row-ac'>
						<Responsive {...Responsive.onlyMobile}>
							<PrimaryButton
								small
								outline
								className='filter-button'
								onClick={() => setIsFilterOpen(!isFilterOpen)}>
								{locContext.filterOn}
							</PrimaryButton>
						</Responsive>
					</div>
				</div>
				<Divider className='no-margin' />
				<Responsive {...Responsive.onlyMobile}>
					{isFilterOpen ? (
						<div className='df-col'>
							<FilterForms
								onFilter={pars => setParams({ ...pars, pageIndex: 1 })}
								params={params}
							/>
							<Divider className='no-margin' />
						</div>
					) : null}
				</Responsive>
				<Responsive minWidth={Responsive.onlyTablet.minWidth}>
					<FilterForms
						onFilter={pars => setParams({ ...pars, pageIndex: 1 })}
						params={params}
					/>
				</Responsive>
				<div className='pos-rel'>
					<Responsive minWidth={Responsive.onlyTablet.minWidth}>
						<BaseTableSortAndPage<IFormSummary, IFormsQueryParams>
							params={params}
							onSortOrPage={t => setParams(t)}
							queryResult={queryResult}
							canSort={true}
							headers={headers}
							uniqueIdentifier='id'
							singleIdentifier={locContext.form}
							onClickRow={inst => viewDetail(inst)}
						/>
					</Responsive>
					<Responsive {...Responsive.onlyMobile}>
						{queryResult && queryResult.values ? (
							<div className='df-col'>
								{queryResult.values.map(t => (
									<div
										className='df-col'
										key={t.id}
										onClick={() => viewDetail(t)}>
										<div className='mobile-single-form'>
											<div className='fg1 df-col'>
												<div className='date font-bold info'>{toDefaultFormat(t.createdDate)}</div>
												<div className='info'>{t.driverName}</div>
												<div className='info'>{t.formatted}</div>
											</div>
										</div>
										<Divider className='no-margin' />
									</div>
								))}
								<PagingFooter
									className='padding-10px'
									singleIdentifier={locContext.form}
									qr={queryResult ? queryResult : {}}
									onNext={() => setParams({ ...params, pageIndex: params.pageIndex ? params.pageIndex + 1 : 1 })}
									onPrevious={() => setParams({ ...params, pageIndex: params.pageIndex ? params.pageIndex - 1 : 1 })}
								/>
							</div>
						) : null}
					</Responsive>
				</div>
			</div>
		</Card>
	);
};
