import React, { useState } from 'react';
import { empty, nameof } from '../infrastructure/Utils';
import { IFormsQueryParams, IFormSummary } from '../gen/ApiClients';

interface IProps {
    params: IFormsQueryParams;
    setParams: (pars: IFormsQueryParams) => void;
}

const fallbackProps: IProps = {
    params: {
        pageIndex: 1,
        pageSize: 10,
        sortDirection: 'descending',
        sortProperty: nameof<IFormSummary>('createdDate')
    },
    setParams: empty,
}

export const FormsQueryParamsContext = React.createContext<IProps>(fallbackProps);

export const FormsQueryParamsProvider = ({ children }: any) => {
    const [params, setParams] = useState<IFormsQueryParams>(fallbackProps.params);

    return (
        <FormsQueryParamsContext.Provider
            value={{
                params: params,
                setParams: setParams,
            }}>
            {children}
        </FormsQueryParamsContext.Provider>
    );
}