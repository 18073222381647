import React, { useContext } from 'react';
import { useLocalizationContext } from '../../hooks/useLocalizationContext';
import { FilterBar } from '../common/filter/FilterBar';
import { IFormsQueryParams } from '../../gen/ApiClients';
import { DateFilterItem } from '../common/filter/DateFilterItem';
import { IOption } from '../../infrastructure/Models';
import { ListFilterItem } from '../common/filter/ListFilterItem';
import { FilterDataContext } from '../../context/FilterDataContext';

interface IProps {
    params: IFormsQueryParams;
    onFilter: (params: IFormsQueryParams) => void;
}

function isFiltered(pars: IFormsQueryParams): boolean {
    if (pars === undefined) {
        return false;
    }
    if (pars.afterDate || pars.beforeDate) {
        return true;
    }
    if (pars.driverIds !== undefined && pars.driverIds.length > 0) {
        return true;
    }
    return false;
}

export const FilterForms = ({ params, onFilter, }: IProps) => {
    const locContext = useLocalizationContext();
    const context = useContext(FilterDataContext);
    const handleClear = () => {
        onFilter({ ...params, afterDate: undefined, beforeDate: undefined, driverIds: [] });
    }
    return (
        <FilterBar
            onClear={() => handleClear()}
            isFiltered={isFiltered(params)}>
            <div className="df-row-ac">
                <DateFilterItem
                    undefinedLabel={locContext.date}
                    afterDate={params.afterDate}
                    beforeDate={params.beforeDate}
                    onFilter={(val) => onFilter({ ...params, afterDate: val.after, beforeDate: val.before })} />
                <ListFilterItem<string>
                    icon={['fas', 'users']}
                    label={locContext.driver}
                    options={context.allFilterableDrivers.map<IOption<string>>(t => ({ key: t.id ? t.id : '', value: t.id ? t.id : '', text: t.value ? t.value : '' }))}
                    selection={params.driverIds ? params.driverIds : []}
                    onFilter={(val) => onFilter({ ...params, driverIds: val })} />
            </div>
        </FilterBar>
    );
}