import React from 'react';
import { IStop } from '../../gen/ApiClients';
import { StopViewComponent } from './StopViewComponent';

interface IProps {
    firstStop: IStop | undefined;
    extraStops: IStop[] | undefined;
    lastStop: IStop | undefined;
}

export const StopsViewComponent = ({ firstStop, extraStops, lastStop, }: IProps) => {
    return (
        <div className="df-col">
            {firstStop ?
                <div className={`df-col`}>
                    <StopViewComponent
                        stop={firstStop} />
                </div>
                : null}
            {extraStops ?
                extraStops.map(t =>
                    <div key={`${t.order}`} className={`df-col`}>
                        <StopViewComponent
                            stop={t} />
                    </div>)
                : null}
            {lastStop ?
                <div className={`df-col`}>
                    <StopViewComponent
                        stop={lastStop} />
                </div>
                : null}
        </div>
    );
}

