import React, { useState } from 'react';
import { Field } from 'formik';
import { ToggleIconButton } from '../buttons/ToggleIconButton';
import { FieldGroup, IFieldGroupProps } from './FieldGroup';
import './PasswordField.scss';

// export const PasswordField = (props: IFieldGroupProps) => {
export function PasswordField<T>(props: IFieldGroupProps<T>) {
    const [isShowPassword, setIsShowPassword] = useState<boolean>(false);

    const toggleShowPassword = (e: any) => {
        e.preventDefault();
        setIsShowPassword(!isShowPassword);
    }

    return (
        <FieldGroup {...props}>
            <div className="password-input-field">
                <Field type={isShowPassword ? 'text' : 'password'} name={props.xName} />
                <ToggleIconButton toggle={toggleShowPassword} checked={isShowPassword} trueIcon={['far', 'eye']} falseIcon={['far', 'eye-slash']} />
            </div>
        </FieldGroup>
    );
}