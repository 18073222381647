import React from 'react';
import { useCombineClassNames } from '../../hooks/useCombineClassNames';
import { TextLinkButton } from '../common/buttons/TextLinkButton';
import { useLocalizationContext } from '../../hooks/useLocalizationContext';
import { INotification } from '../../gen/ApiClients';
import './UnreadNotifications.scss';

interface IProps extends React.HTMLProps<HTMLDivElement> {
    unread: INotification[];
    show: (not: INotification) => void;
}

export const UnreadNotifications = ({ unread, show, ...rest }: IProps) => {
    const locContext = useLocalizationContext();
    const className = useCombineClassNames('bulletins df-col', rest);

    return (
        <div {...rest} className={className}>
            {unread.length === 0 ? <div>{locContext.noNewNotifications}</div> : null}
            {unread.map(t =>
                <div key={t.id} className={`unread-notification df-row-ac jc-sb font-bold${t.isHighPriority ? ' font-error' : ''}`} onClick={() => show(t)}>
                    <div className="un-title">{t.title}</div>
                    <TextLinkButton gray className="un-more-info">{locContext.moreInfo}</TextLinkButton>
                </div>
            )}            
        </div>
    );
}