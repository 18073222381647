import React, { useState, useContext } from 'react';
import { useLocalizationContext } from '../../hooks/useLocalizationContext';
import { FormikProps, Formik, Form, FormikActions } from 'formik';
import { ILeaveOfAbsenceModel, LeaveOfAbsencesClient, LeaveOfAbsenceModel } from '../../gen/ApiClients';
import { TextLinkButton } from '../common/buttons/TextLinkButton';
import { PrimaryButton } from '../common/buttons/PrimaryButton';
import { ToggleCheckbox } from '../common/ToggleCheckbox';
import { SelectField } from '../common/forms/SelectField';
import { FilterDataContext } from '../../context/FilterDataContext';
import { IOption } from '../../infrastructure/Models';
import { SelectDate } from '../common/forms/SelectDate';
import { SelectTime } from '../common/forms/SelectTime';
import { setFieldTouched, setFieldError } from '../common/forms/FormsUtils';
import { useClient } from '../../hooks/useClient';
import { Responsive } from 'semantic-ui-react';
import moment from 'moment';
import * as yup from 'yup';
import './LeaveOfAbsenceForm.scss';

interface IProps {
    onSubmit: (model: ILeaveOfAbsenceModel) => Promise<any>;
    onCancel: () => void;
    model?: ILeaveOfAbsenceModel;
}

interface IModel extends ILeaveOfAbsenceModel {
    fromTime?: string;
    toTime?: string;
}

function addTimeToDate(date: Date, str: string): Date {
    const splits = str.split(':');
    return moment(date).set({ hour: Number(splits[0]), minute: Number(splits[1]), second: 0, millisecond: 0 }).toDate();
}

export const LeaveOfAbsenceForm = ({ onSubmit, onCancel, model }: IProps) => {
    const client = useClient(LeaveOfAbsencesClient);
    const context = useContext(FilterDataContext);
    const options = context.allFilterableDrivers.map<IOption<string>>(t => ({ key: t.id ? t.id : '', value: t.id ? t.id : '', text: t.value ? t.value : '', }));
    const [isWholeDay, setIsWholeDay] = useState<boolean>(true);
    const locContext = useLocalizationContext();
    const validateSchema = isWholeDay === false ?
        yup.object<IModel>().shape<IModel>({
            driverId: yup
                .string()
                .required(locContext.validateSelectDriver),
            fromDate: yup
                .date()
                .required(locContext.validateDate),
            fromTime: yup
                .string()
                .required(locContext.validateFromTime),
            toTime: yup
                .string()
                .required(locContext.validateToTime),
        })
        : yup.object<IModel>().shape<IModel>({
            driverId: yup
                .string()
                .required(locContext.validateSelectDriver),
            fromDate: yup
                .date()
                .required(locContext.validateFromDate),
        })

    const onClickSubmit = async (props: FormikProps<IModel>, e: any) => {
        e.persist();
        const isValid = await validateSchema.isValid(props.values);
        if (!isValid) {
            e.preventDefault();
        }
        setFieldTouched<IModel>('driverId', props);
        setFieldTouched<IModel>('fromDate', props);
        setFieldTouched<IModel>('toDate', props);
        setFieldTouched<IModel>('fromTime', props);
        setFieldTouched<IModel>('toTime', props);
    }

    const handleSubmit = async (values: IModel, actions: FormikActions<IModel>) => {
        actions.setSubmitting(true);
        values = { ...values, isWholeDay: isWholeDay, fromDate: moment(values.fromDate).startOf("day").toDate(), toDate: moment(values.fromDate).endOf('day').toDate() };
        if (!isWholeDay) {
            values = {
                ...values,
                isWholeDay: false,
                fromDate: addTimeToDate(values.fromDate ? values.fromDate : new Date(), values.fromTime ? values.fromTime : '01:00'),
                toDate: addTimeToDate(values.fromDate ? values.fromDate : new Date(), values.toTime ? values.toTime : '00:00')
            }
            if (moment(values.fromTime, 'HH:mm') > moment(values.toTime, 'HH:mm')) {
                setFieldError<IModel>('toTime', locContext.toTimeMustBeLargerThanFromTime, actions);
                actions.setSubmitting(false);
                return;
            }
        }
        const r = await client.canInsert(new LeaveOfAbsenceModel(values));
        if (r.hasError) {
            setFieldError<IModel>('fromDate', r.errorMessage ? r.errorMessage : 'Error!', actions);
        } else {
            await onSubmit(values);
        }
        actions.setSubmitting(false);
    }

    const setTime = (str: string | undefined, key: keyof IModel, props: FormikProps<IModel>) => {
        props.setFieldValue(key, str);
    }

    return (
        <div>
            <Formik<IModel>
                initialValues={model ? model : {}}
                isInitialValid={true}
                validationSchema={validateSchema}
                onSubmit={handleSubmit}>
                {(props) => (
                    <Form>
                        <div className="df-col loa-form-container">
                            <div className="loa-title">{locContext.leaveOfAbsenceFormTitle}</div>
                            <SelectField<IModel> options={options} label={locContext.driver} xName="driverId" />
                            <SelectDate<IModel> label={locContext.date} xName="fromDate" />
                            <div className="df-row-ac jc-sb is-whole-day-option">
                                <div className="is-whole-day-text">{locContext.wholeDay}</div>
                                <ToggleCheckbox externalProps={{ value: isWholeDay, setValue: setIsWholeDay }} />
                            </div>
                            {isWholeDay ? null :
                                <div className="df-col">
                                    <SelectTime<IModel> value={props.values.fromTime} setValue={(val) => setTime(val, 'fromTime', props)} label={locContext.startTime} xName='fromTime' />
                                    <SelectTime<IModel> value={props.values.toTime} setValue={(val) => setTime(val, 'toTime', props)} label={locContext.endTime} xName='toTime' />
                                </div>
                            }
                            <Responsive minWidth={Responsive.onlyTablet.minWidth}>
                                <div className="df-row-ac jc-e buttons-container">
                                    <div className="df-row-ac">
                                        <TextLinkButton className="cancel-button" onClick={() => onCancel()}>{locContext.cancel}</TextLinkButton>
                                        <PrimaryButton disabled={props.isSubmitting} type="submit" onClick={(e) => onClickSubmit(props, e)} showLoader>{locContext.add}</PrimaryButton>
                                    </div>
                                </div>
                            </Responsive>
                            <Responsive {...Responsive.onlyMobile}>
                                <div className="df-col buttons-container">
                                    <PrimaryButton disabled={props.isSubmitting} type="submit" onClick={(e) => onClickSubmit(props, e)} showLoader>{locContext.add}</PrimaryButton>
                                    <TextLinkButton className="cancel-button" onClick={() => onCancel()}>{locContext.cancel}</TextLinkButton>
                                </div>
                            </Responsive>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
}
