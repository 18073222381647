import { IStrings } from './IStrings';

export const fr: IStrings = {
    add: `Ajouter`,
    addExtra: `Ajouter extra`,
    addLeaveOfAbsence: `Ajouter une absence`,
    addStartersPack: `Ajouter un kit de démarrage`,
    after: `Après`,
    all: `Tout`,
    amountExVat: `Montant (hors TVA)`,
    amountInVat: `Montant (ttc)`,
    assignment: `Cession`,
    at: `A`,
    backToLogin: `Retour au login`,
    backToOverview: `Retour à l'aperçu`,
    before: `Devat/Avant`,
    cancel: `Annuler`,
    canceled: `Annulé`,
    coc: `code de conduite`,
    confirmedByDriver: `Confirmé par le chauffeur`,
    contactUs: `Nous contacter`,
    dashboard: `Dashboard`,
    date: `Date`,
    deadFreight: `Erreur de fret`,
    delete: `Supprimer`,
    deleteAll: `Supprimer tout`,
    deleteFilter: `Supprimer les filtres`,
    deleteLeaveOfAbsence: `Supprimer l'absence`,
    departed: `Vertrokken`,
    description: `Descritption`,
    dispute: `Pa sD'accord`,
    disputeData: `Données de contestation`,
    disputeFormSubmitted: `Litige soumis`,
    disputes: `Litiges`,
    download: `Télécharger`,
    downloadTrackAndTrace: `Télécharger Track & Trace`,
    doYouWantToDeleteThisLeaveOfAbsence: `Voulez-vous supprimer définitevement cette absence?`,
    driver: `Chauffeur`,
    driverMobilePhone: `Numéro de gsm chauffeur`,
    drivers: `Chauffeurs`,
    endDate: `Date de fin`,
    endTime: `Heure de fin`,
    export: `Exporter`,
    extras: `Extras`,
    extraStop: `Arrêt supplémentaire`,
    exVat: `hors TVA`,
    fileNumber: `Numéro du dossier`,
    fillIn: `Remplir`,
    fillInQuantity: `Remplir le numéro`,
    filterOn: `Filtrer par`,
    firstStop: `Premier arrêt`,
    forgotPassword: `Mot de passe oublié?`,
    forgotPasswordContent: `Stuur een mail met je gebruikersnaam\nnaar info@eurosprinters.be.\nNadien krijg je instructies om je wachtwoord te wijzigen.`,
    form: `Demande`,
    forms: `Appliquer`,
    formSubmitted: `Formulaire soumis`,
    hello: `Bonjour`,
    hours: `Heures`,
    inProgress: `En cours`,
    internalReference: `Référence interne`,
    invalidCombinationUsernamePassword: `Combinaison nom d'utilisateur / mot de passe invalide`,
    inVat: `ttc`,
    isRead: `Lire?`,
    labelDisputeMessage: `Commentaires`,
    lastMonth: `Mois passé`,
    lastStop: `Dernier stop`,
    lastWeek: `Semaine passée`,
    lastYear: `L'année dernière`,
    leaveOfAbsence: `Absence`,
    leaveOfAbsenceAdded: `Absence ajoutée`,
    leaveOfAbsenceDeleted: `Absence supprimée`,
    leaveOfAbsenceFormTitle: `Ajouter l'absence`,
    load: `Chargement`,
    loadAndUnloadAddressPlusStops: `Adresse de chargement et de déchargement (+ stop)`,
    loaded: `Chargé`,
    loadFile: `Récupérer des données pour le dossier {0}`,
    login: `S'inscrire`,
    loginWelcomeSubtitle: `Connectez-vous avec votre nom d'utilisateur et votre mot de passe`,
    loginWelcomeTitle: `Bienvenu partenaires`,
    logOut: `Deconnexion`,
    markAsRead: `Marquer comme lu`,
    moreInfo: `Plus d'informations`,
    next: `Suivant`,
    no: `Non`,
    noAccount: `Aucun compte`,
    none: `Rien`,
    noNewNotifications: `Il n'y a pas de nouvelles notifications`,
    noRevenues: `No earnings for this month`,
    notification: `Message`,
    notificationMarkedAsRead: `Message marqué comme lu`,
    notificationMarkedAsUnread: `Message marqué comme non lu`,
    notifications: `Notifications`,
    optional: `Facultatif`,
    orderClothing: `Commander des vêtements`,
    outsourced: `Sous-traité`,
    pagingTable: `{0} {1} à {2} à partir {3}`,
    password: `Mot de passe oublié?`,
    previous: `Précédent`,
    price: `Prix`,
    processedDrivers: `Opérationnel effectué`,
    quantity: `Nombre`,
    quantityMustBeAnInteger: `Le nombre doit être un nombre entier`,
    quantityMustBeMinimalOne: `La quantité doit être au minimum 1`,
    read: `Lire?`,
    requestClothing: `Demander des vêtements`,
    revenues: `Les gains`,
    route: `Route`,
    search: `Recherche`,
    searchOnTitleOrDescription: `Recherche par titre ou description`,
    select: `Sélectionner`,
    send: `Expédier`,
    sendToApp: `Envoyé à l'app`,
    serverError: `Une erreur inconnue est survenue sur le serveur. Veuillez réessayer. Si l'erreur persiste, veuillez contacter Euro-Sprinters.`,
    settings: `Paramètres`,
    settlementProposals: `Settlement Proposals`,
    size: `Taille`,
    sizePolo: `Taille Polo`,
    sizeSweatShirt: `Taille sweat`,
    sizeTShirt: `TailleT-Shirt`,
    startDate: `Date de début`,
    startersPackage: `Forfait de départ`,
    startersPackages: `Forfaits de départ`,
    startTime: `Heure de début`,
    startToEndDate: `Date de début - date de fin`,
    status: `Statut`,
    successfullyLoggedOut: `Déconnecté avec succès!`,
    tc: `Conditions générales`,
    thisMonth: `Ce mois`,
    thisWeek: `Cette semaine`,
    thisYear: `Cette année`,
    title: `Titre`,
    today: `Aujourd'hui`,
    total: `Total`,
    totalRevenues: `Total des gains`,
    toTimeMustBeLargerThanFromTime: `L'heure de fin doit être postérieur à l'heure de début`,
    unload: `Décharger`,
    unloaded: `Déchargé`,
    unread: `Pas lu`,
    upForToday: `cela se passe aujourd'hui!`,
    userName: `Nom d'utilisateur`,
    validateDate: `Sélectionnez la date/l'heure`,
    validateDisputeMessage: `Entrez un commentaire`,
    validateFromDate: `Sélectionnez une date de début`,
    validateFromTime: `Sélectionnez une heure de début`,
    validatePassword: `Entrez votre mot de passe`,
    validateSelectDriver: `Sélectionnez un chauffeur`,
    validateToDate: `Sélectionnez la date de fin`,
    validateToTime: `Sélectionnez l'heure de fin`,
    validateUsername: `Entrez votre nom d'utilisateur`,
    vat: `TVA`,
    vehicle: `Véhicule`,
    viewAll: `Voir tout`,
    viewPrintVersion: `Voire print`,
    viewWebVersion: `Voire web`,
    wholeDay: `Journée complète`,
    yes: `Oui`,
    yesDelete: `Oui, supprimer`,
    yesterday: `Hier`,
    yourRevenuesThisMonth: `Vos gains ce mois-ci`,
}
