import { library } from "@fortawesome/fontawesome-svg-core";
import { useContext } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { Login } from "./components/login/Login";
import { AuthContext, AuthProvider } from "./context/AuthContext";
import { BrowserRouterProvider } from "./context/RouterContext";
import { ProtectedRoute } from "./infrastructure/ProtectedRoute";
// library stuff for font-awesome
import {
  faComments,
  faEdit,
  faEye,
  faEyeSlash,
  faFileAlt,
  faSquare,
  faStar as faStarRegular,
} from "@fortawesome/free-regular-svg-icons";
import {
  faArrowLeft,
  faArrowRight,
  faCalendar,
  faCarSide,
  faCheck,
  faCheckCircle,
  faCheckSquare,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faEllipsisV,
  faExclamationTriangle,
  faFileSignature,
  faIndustry,
  faInfo,
  faInfoCircle,
  faLongArrowAltDown,
  faLongArrowAltUp,
  faPlus,
  faReceipt,
  faSearch,
  faStar,
  faTimes,
  faTrash,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { Slide, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.scss";
import { AppUpdateWall } from "./components/AppUpdateWall";
import { Wrapper } from "./components/Wrapper";
import { ForgotPassword } from "./components/login/ForgotPassword";
import { LocalizationProvider } from "./context/LocalizationContext";
import * as fromRoutes from "./routes";
import React from "react";

library.add(
  faEye,
  faEyeSlash,
  faSearch,
  faStar,
  faStarRegular,
  faChevronUp,
  faChevronDown,
  faEdit,
  faEllipsisV,
  faUsers,
  faCheckSquare,
  faSquare,
  faCheck,
  faCalendar,
  faInfo,
  faCarSide,
  faTimes,
  faInfoCircle,
  faIndustry,
  faPlus,
  faCheckCircle,
  faExclamationTriangle,
  faReceipt,
  faChevronLeft,
  faChevronRight,
  faComments,
  faFileSignature,
  faArrowLeft,
  faTrash,
  faLongArrowAltUp,
  faLongArrowAltDown,
  faFileAlt,
  faArrowRight
);

export function App() {
  return (
    <BrowserRouterProvider>
      <LocalizationProvider>
        <AuthProvider>
          <ToastContainer
            position={toast.POSITION.TOP_CENTER}
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={true}
            closeOnClick
            closeButton={false}
            transition={Slide}
            draggable={false}
          />
          <AppUpdateWall>
            <InnerObject />
          </AppUpdateWall>
        </AuthProvider>
      </LocalizationProvider>
    </BrowserRouterProvider>
  );
}

const InnerObject = () => {
  const authContext = useContext(AuthContext);
  return (
    <Switch>
      <Route exact path={fromRoutes.LoginRoute} component={Login} />
      <Route
        exact
        path={fromRoutes.ForgotPasswordRoute}
        component={ForgotPassword}
      />
      {authContext.hasToken ? (
        <ProtectedRoute component={Wrapper} />
      ) : (
        <Redirect to={fromRoutes.LoginRoute} />
      )}
    </Switch>
  );
};
