import React from 'react';
import { ILeaveOfAbsenceSummary } from '../../gen/ApiClients';
import { TextLinkButton } from '../common/buttons/TextLinkButton';
import { PrimaryButton } from '../common/buttons/PrimaryButton';
import { useLocalizationContext } from '../../hooks/useLocalizationContext';
import { toDefaultFormat } from '../../infrastructure/Utils';
import './LeaveOfAbsenceDetail.scss';
import { IconButton } from '../common/buttons/IconButton';
import { Responsive } from 'semantic-ui-react';

interface IProps {
    loas: ILeaveOfAbsenceSummary[];
    onCancel: () => void;
    onDelete: (id: string | undefined) => void;
    onDeleteAll: (id: (string | undefined)[]) => void;
}
export const LeaveOfAbsenceDetail = ({ loas, onCancel, onDelete, onDeleteAll }: IProps) => {
    const locContext = useLocalizationContext();

    return (
        <div className="df-col loa-detail-container stretch-ver">
            <div className="df-col loa-detail-inner">
                <div className="loa-title">
                    {locContext.leaveOfAbsence}
                </div>
                <div className="label">{locContext.driver}</div>
                <div className="info">{loas[0].driverName}</div>
                {loas[0].isWholeDay ?
                    <div className="df-col">
                        <div className="label">{locContext.date} ({locContext.wholeDay})</div>
                        <div className="info">{toDefaultFormat(loas[0].date)}</div>
                    </div>
                    : <div className="df-col">
                        <div className="label">{locContext.date}</div>
                        <div className="info">{toDefaultFormat(loas[0].date)}</div>
                        <div className="label">{locContext.startTime} - {locContext.endTime} ({locContext.hours})</div>
                        {loas.map(t => <div key={t.id} className="info">{t.fromTime} - {t.toTime} ({t.hours}) {loas.length > 1 ? <IconButton color="gray" className="delete-loa-button" icon={['fas', 'trash']} onClick={() => onDelete(t.id)} /> : null}</div>)}
                    </div>}
            </div>
            <Responsive minWidth={Responsive.onlyTablet.minWidth}>
                <div className="df-row-ac jc-e">
                    <div className="df-row-ac">
                        <TextLinkButton className="cancel-button" onClick={() => onCancel()}>{locContext.cancel}</TextLinkButton>
                        <PrimaryButton onClick={() => onDeleteAll(loas.flatMap(t => t.id))}>{loas.length > 1 ? locContext.deleteAll : locContext.delete}</PrimaryButton>
                    </div>
                </div>
            </Responsive>
            <Responsive {...Responsive.onlyMobile}>
                <div className="df-col">
                    <PrimaryButton onClick={() => onDeleteAll(loas.flatMap(t => t.id))}>{loas.length > 1 ? locContext.deleteAll : locContext.delete}</PrimaryButton>
                    <TextLinkButton className="cancel-button" onClick={() => onCancel()}>{locContext.cancel}</TextLinkButton>
                </div>
            </Responsive>
        </div>
    );
}