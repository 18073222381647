import React from 'react';
import { IFieldGroupProps, FieldGroup } from './FieldGroup';
import { DefaultTimeInput } from '../fields/DefaultTimeInput';
import './SelectTime.scss';

interface IProps<T> extends IFieldGroupProps<T> {
    value: string | undefined;
    setValue: (str: string | undefined) => void;
}

export function SelectTime<T>({ value, setValue, ...props }: IProps<T>) {
    return (
        <FieldGroup {...props}>
            <DefaultTimeInput
                className="field-time-input"
                value={value}
                onValueChanged={setValue} />
        </FieldGroup>
    );
}