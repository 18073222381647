import React from 'react';
import { INotification } from '../../gen/ApiClients';
import { BaseConfirmation } from '../common/modal/BaseConfirmation';
import { useLocalizationContext } from '../../hooks/useLocalizationContext';
import { BaseInformation } from '../common/modal/BaseInformation';
import { toDefaultFormat } from '../../infrastructure/Utils';

interface IProps {
    notification: INotification;
    cancel: () => void;
    confirm: () => Promise<any>;
}

export const NotificationDetail = ({ notification, cancel, confirm }: IProps) => {
    const locContext = useLocalizationContext();
    if (notification.isRead) {
        return (
            <BaseInformation
                title={`${toDefaultFormat(notification.date)} - ${notification.title}`}
                description={notification.description} />
        );
    }
    return (
        <BaseConfirmation
            title={`${toDefaultFormat(notification.date)} - ${notification.title}`}
            description={notification.description}
            confirmText={locContext.markAsRead}
            cancelText={locContext.cancel}
            cancel={cancel}
            confirm={confirm} />
    );
}