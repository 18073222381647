import React from 'react';
import { TimeInput } from 'semantic-ui-calendar-react';
import { useCombineClassNames } from '../../../hooks/useCombineClassNames';

interface IProps {
    placeholder?: string;
    value: string | undefined;
    onValueChanged: (val: string | undefined) => void;
    className?: string;
}

export const DefaultTimeInput = ({ placeholder, value, onValueChanged, ...rest }: IProps) => {
    const handleChange = (e: any, data: any) => {
        onValueChanged(data.value);
    }
    const handleClear = () => {
        onValueChanged(undefined);
    }

    return (
        <TimeInput
            {...rest}
            className={useCombineClassNames('', rest)}
            value={value ? value : ''}
            onChange={handleChange}
            placeholder={placeholder}
            clearable
            onClear={handleClear}
            animation='fade'
            closable
            iconPosition='left' />
    );
}