import React, { useContext, useState } from 'react';
import { useLocalizationContext } from '../hooks/useLocalizationContext';
import { AuthContext } from '../context/AuthContext';
import { NotificationsProvider } from '../context/NotificationsContext';
import { WrapperSearchProvider } from '../context/WrapperSearchTextContext';
import { Logo } from './common/Logo';
import { NavLink, Switch, Redirect } from 'react-router-dom';
import { WrapperSearchbox } from './WrapperSearchbox';
import { NotificationsDropdown } from './NotificationsDropdown';
import { ProtectedRoute } from '../infrastructure/ProtectedRoute';
import { Dashboard } from './dashboard/Dashboard';
import { Revenues } from './revenues/Revenues';
import { FilterDataProvider } from '../context/FilterDataContext';
import { ModalProvider } from '../context/ModalContext';
import { LeaveOfAbsences } from './leaveOfAbsences/LeaveOfAbsences';
import { NotificationsQueryParamsProvider } from '../context/NotificationsQueryParamsContext';
import { Notifications } from './notifications/Notifications';
import { Revenue } from './revenues/Revenue';
import { FullScreenLoader } from './common/loaders/FullScreenLoader';
import { WrapperFullScreenLoadContext, WrapperFullScreenLoadProvider } from '../context/WrapperFullScreenLoadContext';
import { FormsQueryParamsProvider } from '../context/FormsQueryParamsContext';
import { Forms } from './forms/Forms';
import { Divider, Responsive } from 'semantic-ui-react';
import HamburgerMenu from 'react-hamburger-menu';
import { MobileNotifications } from './MobileNotifications';
import { MobileFooter } from './mobileFooter/MobileFooter';
import { SettlementProposals } from './settlementProposals/SettlementProposals';
import { Settings } from './settings/Settings';
import * as routes from '../routes';
import './Wrapper.scss';
import { SettlementProposalQueryParamsProvider } from '../context/SettlementProposalsQueryParamsProvider';
import { RevenuesQueryParamsProvider } from '../context/RevenuesQueryParamsProvider';
import { LeaveOfAbsencesQueryParamsProvider } from '../context/LeaveOfAbsencesQueryParamsProvider';
import { LocalizationContext } from '../context/LocalizationContext';


export const Wrapper = () => {
	return (
		<FilterDataProvider>
			<RevenuesQueryParamsProvider>
				<LeaveOfAbsencesQueryParamsProvider>
					<NotificationsQueryParamsProvider>
						<SettlementProposalQueryParamsProvider>
							<FormsQueryParamsProvider>
								<ModalProvider>
									<NotificationsProvider>
										<WrapperSearchProvider>
											<WrapperFullScreenLoadProvider>
												<InnerObject />
											</WrapperFullScreenLoadProvider>
										</WrapperSearchProvider>
									</NotificationsProvider>
								</ModalProvider>
							</FormsQueryParamsProvider>
						</SettlementProposalQueryParamsProvider>
					</NotificationsQueryParamsProvider>
				</LeaveOfAbsencesQueryParamsProvider>
			</RevenuesQueryParamsProvider>
		</FilterDataProvider>
	);
};

const InnerObject = () => {
	const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
	const loaderContext = useContext(WrapperFullScreenLoadContext);

	return (
		<div className='stretch-ver stretch-hor'>
			<Responsive
				{...Responsive.onlyMobile}
				className='stretch-ver'>
				<div className='df-col container'>
					<div className='sticky-at-top z-index-1 stretch-hor'>
						<MobileHeader
							isMenuOpen={isMenuOpen}
							toggle={() => setIsMenuOpen(!isMenuOpen)}
						/>
					</div>
					{isMenuOpen ? (
						<div className='df-col stretch-ver mobile-menu'>
							<LowerNavLinks clicked={() => setIsMenuOpen(false)} />
						</div>
					) : (
						<div className='mobile-body-wrapper df-col pos-rel'>
							{loaderContext.isLoading ? <FullScreenLoader /> : null}
							<div className='padding-10px'>
								<BodyContent />
							</div>
						</div>
					)}
					{isMenuOpen ? null : (
						<div className='nav-footer df-row stretch-hor'>
							<MobileFooter />
						</div>
					)}
				</div>
			</Responsive>
			<Responsive
				minWidth={Responsive.onlyTablet.minWidth}
				className='stretch-ver'>
				<div className='df-row container'>
					<div className='side-nav sticky-at-top'>
						<SideNav />
					</div>
					<div className='body-wrapper df-col pos-rel'>
						{loaderContext.isLoading ? <FullScreenLoader /> : null}
						<div className='sticky-at-top z-index-1'>
							<DesktopHeader />
						</div>
						<div className='body-content'>
							<BodyContent />
						</div>
					</div>
				</div>
			</Responsive>
		</div>
	);
};

const BodyContent = () => {
	return (
		<Switch>
			<ProtectedRoute
				path={routes.DashboardRoute}
				component={Dashboard}
			/>
			<ProtectedRoute
				path={routes.RevenueDetailRoute}
				component={Revenue}
			/>
			<ProtectedRoute
				path={routes.RevenuesRoute}
				component={Revenues}
			/>
			<ProtectedRoute
				path={routes.SettingsRoute}
				component={Settings}
			/>
			<ProtectedRoute
				path={routes.LeaveOfAbsencesRoute}
				component={LeaveOfAbsences}
			/>
			<ProtectedRoute
				path={routes.NotificationsRoute}
				component={Notifications}
			/>
			<ProtectedRoute
				path={routes.FormsRoute}
				component={Forms}
			/>
			<ProtectedRoute
				path={routes.SettlementProposalsRoute}
				component={SettlementProposals}
			/>
			<Redirect
				path='/'
				to={routes.DashboardRoute}
			/>
		</Switch>
	);
};

const SideNav = () => {
	const locContext = useLocalizationContext();
	const localization = useContext(LocalizationContext);
	const authContext = useContext(AuthContext);
	const lang = localization.getLanguage().toUpperCase()

	return (
		<div className='df-col stretch-ver jc-sb'>
			<div className='df-col'>
				<Logo />
				<div className='nav-links df-col'>
					<NavLink
						className='upper-nav-link'
						to={routes.DashboardRoute}>
						{locContext.dashboard}
					</NavLink>
					<NavLink
						className='upper-nav-link'
						to={routes.RevenuesRoute}>
						{locContext.revenues}
					</NavLink>
					<NavLink
						className='upper-nav-link'
						to={routes.LeaveOfAbsencesRoute}>
						{locContext.leaveOfAbsence}
					</NavLink>
					<NavLink
						className='upper-nav-link'
						to={routes.FormsRoute}>
						{locContext.forms}
					</NavLink>
					<NavLink
						className='upper-nav-link'
						to={routes.NotificationsRoute}>
						{locContext.notifications}
					</NavLink>
					<NavLink
						className='upper-nav-link'
						to={routes.SettlementProposalsRoute}>
						{locContext.settlementProposals}
					</NavLink>
				</div>
			</div>
			<div className='nav-links df-col'>
				<div className='company-name'>{authContext.user ? authContext.user.companyName : ''}</div>
				<NavLink
					className='lower-nav-link'
					to={routes.SettingsRoute}>
					{locContext.settings}
				</NavLink>
				{/* eslint-disable-next-line */}
				<a
					className='lower-nav-link'
					onClick={() => authContext.logout()}>
					{locContext.logOut}
				</a>

				<Divider/>
				<div className='df-col' >
				<a href={"https://eurosprinterspublicfiles.blob.core.windows.net/pdf/T&C_"+lang+".pdf"} target = "_blank">{locContext.tc}</a>
				<a className='lower-nav-link' href={"https://eurosprinterspublicfiles.blob.core.windows.net/pdf/COC_"+lang+".pdf"} target = "_blank">{locContext.coc}</a>
				</div>
			</div>
		</div>
	);
};

interface ILowerNavLinksProps {
	clicked?: () => void;
}
const LowerNavLinks = ({ clicked }: ILowerNavLinksProps) => {
	const authContext = useContext(AuthContext);
	const locContext = useLocalizationContext();
	const localization = useContext(LocalizationContext);
	const lang = localization.getLanguage().toUpperCase()
	return (
		<div className='nav-links df-col'>
			<div className='company-name'>{authContext.user ? authContext.user.companyName : ''}</div>
			<NavLink
				className='lower-nav-link'
				to={routes.SettingsRoute}
				onClick={() => {
					if (clicked) {
						clicked();
					}
				}}>
				{locContext.settings}
			</NavLink>
			{/* eslint-disable-next-line */}
			<a
				className='lower-nav-link'
				onClick={() => {
					authContext.logout();
					if (clicked) {
						clicked();
					}
				}}>
				{locContext.logOut}
			</a>
			<Divider/>
				<div className='df-col' >
				<a className='lower-nav-link' href={"https://eurosprinterspublicfiles.blob.core.windows.net/pdf/T&C_"+lang+".pdf"} target = "_blank">{locContext.tc}</a>
				<a className='lower-nav-link' href={"https://eurosprinterspublicfiles.blob.core.windows.net/pdf/COC_"+lang+".pdf"} target = "_blank">{locContext.coc}</a>
				</div>
		</div>
	);
};

const DesktopHeader = () => {
	const authContext = useContext(AuthContext);

	return (
		<div className='top-bar df-row-ac jc-sb'>
			<WrapperSearchbox className='search-anything' />
			<div className='df-row-ac'>
				<NotificationsDropdown />
				<div className='user-text'>{authContext.user ? authContext.user.name : ''}</div>
			</div>
		</div>
	);
};

interface IMobileHeaderProps {
	isMenuOpen: boolean;
	toggle: () => void;
}
const MobileHeader = ({ isMenuOpen, toggle }: IMobileHeaderProps) => {
	const authContext = useContext(AuthContext);
	const localization = useContext(LocalizationContext);

	return (
		<div className='df-col'>
			<div className='top-bar-mobile df-row-ac'>
				<div className='df-row-afs-left'>
					<HamburgerMenu
						isOpen={isMenuOpen}
						menuClicked={() => toggle()}
						color='black'
						width={18}
						height={15}
					/>
				</div>
				<div className='user-text-mobile'>{authContext.user ? authContext.user.name : ''}</div>
				<div className='df-row-afs-right'>
					<NavLink to={routes.NotificationsRoute}>
						<MobileNotifications />
					</NavLink>
				</div>
			</div>
			<WrapperSearchbox className='search-anything' />
		</div>
	);
};
