import React, { useState, useEffect, useContext } from 'react';
import { Card, Responsive, Divider } from 'semantic-ui-react';
import { INotification, INotificationQueryResult, NotificationsQueryParams, NotificationsClient, INotificationsQueryParams } from '../../gen/ApiClients';
import { toDefaultFormat, tryCatchWithLoading } from '../../infrastructure/Utils';
import { useLocalizationContext } from '../../hooks/useLocalizationContext';
import { NotificationsQueryParamsContext } from '../../context/NotificationsQueryParamsContext';
import { useClient } from '../../hooks/useClient';
import { FilterNotifications } from './FilterNotifications';
import { BaseTableSortAndPage } from '../common/table/BaseTableSortAndPage';
import { NotificationsContext } from '../../context/NotificationsContext';
import { createHeader, createSortableHeader } from '../common/table/TableUtils';
import { WrapperSearchContext } from '../../context/WrapperSearchTextContext';
import { WrapperFullScreenLoadContext } from '../../context/WrapperFullScreenLoadContext';
import { PrimaryButton } from '../common/buttons/PrimaryButton';
import { PagingFooter } from '../common/table/PagingFooter';
import './Notifications.scss';

export const Notifications = () => {
	const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
	const locContext = useLocalizationContext();
	const [queryResult, setQueryResult] = useState<INotificationQueryResult>();
	const { params, setParams } = useContext(NotificationsQueryParamsContext);
	const client = useClient(NotificationsClient);
	const context = useContext(NotificationsContext);
	const wrapperLoaderContext = useContext(WrapperFullScreenLoadContext);
	useContext(WrapperSearchContext).init(`${locContext.searchOnTitleOrDescription}...`, params.searchString, (str: string) => {
		setParams({ ...params, searchString: str, pageIndex: 1 });
	});

	useEffect(
		() => {
			load();
		},
		// eslint-disable-next-line
		[params, context.trigger]
	);

	const load = async () => {
		setQueryResult(await tryCatchWithLoading(client.query(new NotificationsQueryParams(params)), wrapperLoaderContext.setLoading, locContext.serverError));
	};

	const headers = [
		createSortableHeader<INotification>(locContext.date, 'date', t => toDefaultFormat(t.date)),
		createHeader<INotification>(locContext.title, t => t.title),
		createHeader<INotification>(locContext.description, t => t.description),
		createHeader<INotification>(locContext.status, t => (t.isRead ? locContext.read : locContext.unread)),
	];

	const formatRow = (inst: INotification): string => {
		const x1 = inst.isRead ? '' : 'font-bold ';
		const x2 = inst.isHighPriority ? 'font-error' : '';
		return x1 + x2;
	};

	return (
		<Card className='notifications-card'>
			<div className='df-col stretch-ver'>
				<div className='df-row-ac jc-sb notifications-header'>
					<div>{locContext.notifications}</div>
					<Responsive {...Responsive.onlyMobile}>
						<PrimaryButton
							small
							outline
							className='filter-button'
							onClick={() => setIsFilterOpen(!isFilterOpen)}>
							{locContext.filterOn}
						</PrimaryButton>
					</Responsive>
				</div>
				<Divider className='no-margin' />
				<Responsive {...Responsive.onlyMobile}>
					{isFilterOpen ? (
						<div className='df-col'>
							<FilterNotifications
								onFilter={pars => setParams({ ...pars, pageIndex: 1 })}
								params={params}
							/>
							<Divider className='no-margin' />
						</div>
					) : null}
				</Responsive>
				<Responsive minWidth={Responsive.onlyTablet.minWidth}>
					<FilterNotifications
						onFilter={pars => setParams({ ...pars, pageIndex: 1 })}
						params={params}
					/>
				</Responsive>
				<Responsive minWidth={Responsive.onlyTablet.minWidth}>
					<BaseTableSortAndPage<INotification, INotificationsQueryParams>
						params={params}
						onSortOrPage={t => setParams(t)}
						queryResult={queryResult}
						canSort={true}
						headers={headers}
						uniqueIdentifier='id'
						singleIdentifier={locContext.notification}
						onClickRow={inst => context.show(inst)}
						formatRow={inst => formatRow(inst)}
					/>
				</Responsive>
				<Responsive {...Responsive.onlyMobile}>
					{queryResult && queryResult.values ? (
						<div className='df-col'>
							{queryResult.values.map(t => (
								<div
									className='df-col'
									key={t.id}
									onClick={() => context.show(t)}>
									<div className={`mobile-single-notification padding-10px df-row${t.isRead ? '' : ' unread'}${t.isHighPriority ? ' high-priority' : ''}`}>
										<div className='fg1 df-col'>
											<div className='info'>{t.title}</div>
											<div className='info-italic'>{t.description}</div>
										</div>
									</div>
									<Divider className='no-margin' />
								</div>
							))}
							<PagingFooter
								className='padding-10px'
								singleIdentifier={locContext.notification}
								qr={queryResult ? queryResult : {}}
								onNext={() => setParams({ ...params, pageIndex: params.pageIndex ? params.pageIndex + 1 : 1 })}
								onPrevious={() => setParams({ ...params, pageIndex: params.pageIndex ? params.pageIndex - 1 : 1 })}
							/>
						</div>
					) : null}
				</Responsive>
			</div>
		</Card>
	);
};
