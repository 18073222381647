import moment from 'moment';
import React, { useState } from 'react';
import { IRevenuesQueryParams } from '../gen/ApiClients';
import { RevenuesQueryParamsContext } from './RevenuesQueryParamsContext';

const DefaultParams: IRevenuesQueryParams = {
	afterDate: moment(new Date()).startOf('month').toDate(),
	beforeDate: moment(new Date()).endOf('month').toDate(),
};

export const RevenuesQueryParamsProvider = ({ children }: any) => {
	const [params, setParams] = useState<IRevenuesQueryParams>(DefaultParams);

	return (
		<RevenuesQueryParamsContext.Provider
			value={{
				params: params,
				setParams: setParams,
			}}>
			{children}
		</RevenuesQueryParamsContext.Provider>
	);
};
