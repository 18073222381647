import { EffectCallback, DependencyList, useState, useEffect } from 'react';

export function useEffectAfterMount(effect: EffectCallback, deps?: DependencyList): void{
    const [didMount, setDidMount] = useState<boolean>(false);
    useEffect(() => { setDidMount(true)}, []);
    useEffect(() => {
        if (didMount){
            effect();
        }
    }, 
    // eslint-disable-next-line
    deps);
}