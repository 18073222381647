import React, { useState, useEffect } from 'react';
import { IOrderModel, IPackage, Package, IExtra, Extra, IExtraProduct, FormsClient } from '../../../gen/ApiClients';
import { MainForm } from './MainForm';
import { ExtraForm } from './ExtraForm';
import { PackageForm } from './PackageForm';
import { useClient } from '../../../hooks/useClient';
import './OrderClothingForm.scss';

interface IProps {
    onSubmit: (model: IOrderModel) => Promise<any>;
    onCancel: () => void;
}

export const OrderClothingForm = ({ onSubmit, onCancel }: IProps) => {
    const [state, setState] = useState<'main' | 'package' | 'extra'>('main');
    const [model, setModel] = useState<IOrderModel>({ driverId: '', mobilePhoneDriver: '' });
    const [products, setProducts] = useState<IExtraProduct[]>([]);
    const client = useClient(FormsClient);
    
    useEffect(() => {
        load();
    }, 
    // eslint-disable-next-line
    []);
    
    const load = async () => {
        setProducts(await client.allProducts());
    }

    const addPackage = (pack: IPackage) => {
        const x = model && model.starterPacks ? [...model.starterPacks, new Package(pack)] : [new Package(pack)];
        setModel({ ...model, starterPacks: x });
        setState('main');
    }
    const deletePackage = (pack: IPackage) => {
        if (!model || !model.starterPacks) {
            return;
        }
        const index = (model.starterPacks as IPackage[]).indexOf(pack);
        const x = [...model.starterPacks.slice(0, index), ...model.starterPacks.slice(index + 1)];
        setModel({ ...model, starterPacks: x });
    }
    const addExtra = (extra: IExtra) => {
        const x = model && model.extras ? [...model.extras, new Extra(extra)] : [new Extra(extra)];
        setModel({ ...model, extras: x });
        setState('main');
    }
    const deleteExtra = (extra: IExtra) => {
        if (!model || !model.extras) {
            return;
        }
        const index = (model.extras as IExtra[]).indexOf(extra);
        const x = [...model.extras.slice(0, index), ...model.extras.slice(index + 1)];
        setModel({ ...model, extras: x });
    }

    if (state === 'main') {
        return <MainForm onSubmit={onSubmit} onCancel={onCancel}
            addExtra={() => setState('extra')} deleteExtra={deleteExtra}
            addPackage={() => setState('package')} deletePackage={deletePackage}
            products={products}
            model={model}
            setModel={(val) => setModel({...model, driverId: val.driverId, mobilePhoneDriver: val.mobilePhoneDriver })} />
    } else if (state === 'extra') {
        return <ExtraForm onPrevious={() => setState('main')} onSubmit={addExtra} products={products} />
    } else if (state === 'package') {
        return <PackageForm onPrevious={() => setState('main')} onSubmit={addPackage} />
    } else {
        return <div>Whoops, nothing found...</div>
    }

}
