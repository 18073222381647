import { DefaultDateFormat } from "../constants";
import moment from 'moment';
import { ISortProps, IOption } from "./Models";
import { showErrorToast } from "../components/common/toast/ToastNotification";
import { reject } from "q";

export function translateSortDirection(sortDirection: string | undefined) : 'ascending' | 'descending' | undefined{
    if (sortDirection === undefined){
        return undefined;
    } else if (sortDirection.toLowerCase().startsWith('asc')) {
        return 'ascending';
    } else {
        return 'descending';
    }
}

export function nameof<T>(key: keyof T): string {
    return key.toString();
}

export function isSorted<T>(property: keyof T, {sortDirection, sortProperty}: ISortProps): 'ascending' | 'descending' | undefined {
    if (property === sortProperty) {
        return translateSortDirection(sortDirection);
    }
    return undefined;
}

export function toDefaultDate(strValue: string): Date {
    return moment(strValue, DefaultDateFormat).toDate();
}

export function toDefaultStartOfDay(strValue: string): Date {
    return moment(strValue, DefaultDateFormat).startOf("day").toDate();
}

export function toDefaultEndOfDay(strValue: string): Date {
    return moment(strValue, DefaultDateFormat).endOf("day").toDate();
}

export function toDefaultFormat(date: Date | undefined): string {
    return date ? moment(date, DefaultDateFormat).format(DefaultDateFormat) : '';
}

export function toDateAndTimeFormat(date: Date | undefined): string {
    return date ? moment(date, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY (HH:mm)') : '';
}

export function empty() {
}

export function stringIsNumber(value: string): boolean {
    return isNaN(Number(value)) === false;
}

export function enumToOptions<T>(enumType: any, translations?: Map<any, string>): IOption<T>[] {
    let output: IOption<T>[] = [];
    Object.keys(enumType)
        .filter(stringIsNumber)
        .forEach(t => output.push({ key: enumType[enumType[t]], text: translations ? translations.get(enumType[enumType[t]]) : enumType[t], value: enumType[t] }));
    return output;
}

// export function enumToOptions(enumType: any, translations?: Map<any, string>): IOption<number>[] {
//     let output: IOption<number>[] = [];
//     Object.keys(enumType)
//         .filter(stringIsNumber)
//         .forEach(t => output.push({ key: enumType[enumType[t]], text: translations ? translations.get(enumType[enumType[t]]) : enumType[t], value: enumType[t] }));
//     return output;
// }

export function clearNullOrUndefinedValues<T>(obj: T | undefined, type: new() => T): T{
    if (!obj){
        obj = new type();
    }
    const x = nonGenericClearNullOrUndefinedValues(obj);
    return x as T;
}

function nonGenericClearNullOrUndefinedValues(obj: any | undefined): any {
    const n = {...obj};
    const tt = Object.entries(n).filter(t => t[1] === null || t[1] === undefined);
    tt.forEach(x => n[x[0]] = '');
    return n;
}

export function pad(num: number, size: number): string {
    var s = num+"";
    while (s.length < size) s = "0" + s;
    return s;
}

export function toOptions(input: string[]) : IOption<string>[]{
    return input.map<IOption<string>>(t => ({key: t ? t : '', value: t ? t : '', text: t ? t : ''}));
}

export function clearCacheAndReloadApplication() {
    if (caches) {
        caches.keys().then((names) => {
            console.log('clearing caches')
            for (let name of names) {
                caches.delete(name);
            }
        });
    }
    console.log('reloading');
    window.location.reload(true);
}

export async function tryCatchWithLoading<T>(fnc: Promise<T>, setIsLoading: (isLoading: boolean) => void, errorMessage: string = 'An unknown exception occurred at server, please try again'): Promise<T> {
    setIsLoading(true);
    try {
        const x = await fnc;
        setIsLoading(false);
        return x;
    } catch (error) {
        showErrorToast(errorMessage);
        setIsLoading(false);
        return reject();
    }
}

export async function tryCatch<T>(fnc: Promise<T>, errorMessage: string = 'An unknown exception occurred at server, please try again'): Promise<T> {
    return await tryCatchWithLoading(fnc, empty, errorMessage);
}

