import React, { useState } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './FilterItem.scss';
import { useCombineClassNames } from '../../../hooks/useCombineClassNames';

interface IProps extends React.HTMLProps<HTMLDivElement> {
    icon: IconProp;
    count: number;
    label: string;
    onClickAway: () => void;
    noMaxHeight?: boolean;
    onOpened: () => void;
}

export const FilterItem = ({ children, icon, count, label, onClickAway, noMaxHeight = false, onOpened = () => {}, ...rest }: IProps) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    return (
        <Dropdown
            className="top-level-div"
            trigger={<FilterTrigger
                count={count}
                icon={icon}
                label={label}
                className={rest.className}></FilterTrigger>}
            icon={null}
            floating
            onClose={() => { }}
            onOpen={() => {setIsOpen(true); onOpened();}}
            open={isOpen}
            onBlur={() => { setIsOpen(false); onClickAway(); }}
            scrolling>
            <Dropdown.Menu className={`dd-menu${noMaxHeight ? ' no-max-height' : ''}`}>
                {children}
            </Dropdown.Menu>
        </Dropdown>
    );
}

interface ITriggerProps {
    count: number;
    label: string;
    icon: IconProp;
    className?: string;
}

const FilterTrigger = ({ count, label, icon, ...rest }: ITriggerProps) => {
    return (
        <div className={useCombineClassNames('df-row-ac filter-trigger', rest)}>
            <div className={`ft-icon df-row-ac jc-c${count > 0 ? ' ft-icon-selected' : ''}`}>
                <FontAwesomeIcon icon={icon} />
                {count > 0 ?
                    <div className="ft-label">{count}</div>
                    : null}
            </div>
            <div>{label}</div>
        </div>
    );
}