import React, { useState, useContext, useEffect } from 'react';
import { useCombineClassNames } from '../hooks/useCombineClassNames';
import { WrapperSearchContext } from '../context/WrapperSearchTextContext';
import { Searchbox } from './common/search/Searchbox';
import './WrapperSearchbox.scss';
import { TextLinkButton } from './common/buttons/TextLinkButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocalizationContext } from '../hooks/useLocalizationContext';
import { RouterContext } from '../context/RouterContext';

interface IProps extends React.HTMLProps<HTMLDivElement> {
}

export const WrapperSearchbox = ({ ...rest }: IProps) => {
    const className = useCombineClassNames('dashboard-search-box df-row-ac', rest);
    const locContext = useLocalizationContext();
    const routerContext = useContext(RouterContext);
    const [text, setText] = useState<string>('');
    const context = useContext(WrapperSearchContext);
    const search = (str: string) => {
        context.onSearch(str);
    }
    useEffect(() => {
        if (context.searchText !== text) {
            setText(context.searchText ? context.searchText : '');
        }
    }, 
    // eslint-disable-next-line
    [context.searchText]);

    if (context.isHidden) {
        if (context.isShowGoBack === false) {
            return <div></div>;
        } else {
            return (
                <TextLinkButton onClick={() => routerContext.goBack()}>
                    <div className="df-row-ac go-back-button">
                        <FontAwesomeIcon icon={['fas', 'arrow-left']} className="mr-5px" />
                        <div>{locContext.backToOverview}</div>
                    </div>
                </TextLinkButton>
            );
        }
    }

    return (
        <Searchbox
            className={className}
            placeholder={context.placeholder}
            externalProps={{ text: text, setText: setText }}
            onSearch={search}
            onTextChanged={(str) => setText(str)} />
    );
}