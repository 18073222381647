import { PDFViewer } from '@react-pdf/renderer';
import React, { useContext, useEffect, useState } from 'react';
import { Card, Divider, Responsive } from 'semantic-ui-react';
import { ModalContext } from '../../context/ModalContext';
import { RevenuesQueryParamsContext } from '../../context/RevenuesQueryParamsContext';
import { WrapperFullScreenLoadContext } from '../../context/WrapperFullScreenLoadContext';
import { WrapperSearchContext } from '../../context/WrapperSearchTextContext';
import { IRevenuesQueryResult, RevenuesClient, RevenuesQueryParams } from '../../gen/ApiClients';
import { useClient } from '../../hooks/useClient';
import { useLocalizationContext } from '../../hooks/useLocalizationContext';
import { tryCatchWithLoading } from '../../infrastructure/Utils';
import { InformationIconButton } from '../common/buttons/InformationIconButton';
import { PrimaryButton } from '../common/buttons/PrimaryButton';
import { MonthSelector } from '../common/filter/MonthSelector';
import { ExportDocument } from './export/ExportDocument';
import { FilterRevenues } from './FilterRevenues';
import { RevenuePerDriver } from './RevenuePerDriver';
import './Revenues.scss';
import { RevenuesSummary } from './RevenuesSummary';

export const Revenues = () => {
    const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
    const locContext = useLocalizationContext();
    const client = useClient(RevenuesClient);
    const [queryResult, setQueryResult] = useState<IRevenuesQueryResult>({});
    const { params, setParams } = useContext(RevenuesQueryParamsContext);
    const modalContext = useContext(ModalContext);
    useContext(WrapperSearchContext).hide();
    const wrapperLoaderContext = useContext(WrapperFullScreenLoadContext);
    const [isShowPrintVersion, setIsShowPrintVersion] = useState<boolean>(false);

    useEffect(() => {
        loadRevenues();
    },
        // eslint-disable-next-line
        [params]);

    const loadRevenues = async () => {
        const t = await tryCatchWithLoading(client.query(new RevenuesQueryParams(params)), wrapperLoaderContext.setLoading, locContext.serverError);
        setQueryResult({});
        setQueryResult(t);
    }

    const showTotals = () => {
        if (!queryResult.values) {
            return;
        }
        modalContext.open(
            <RevenuesSummary
                afterDate={queryResult.afterDate}
                beforeDate={queryResult.beforeDate}
                drivers={queryResult.values.map(t => t.driverName ? t.driverName : '')}
                amountExVat={queryResult.totalAmountExVat}
                amountInVat={queryResult.totalAmountInVat}
                vatAmount={queryResult.totalVatAmount}
                previousMonthAmountExVat={queryResult.previousMonthTotalAmountExVat}
                percentageChangeAmountExVat={queryResult.percentageChangeTotalAmountExVat} />
            , true);
    }

    return (
        <div className={`df-col${isShowPrintVersion ? ' stretch-ver' : ''}`}>
            <Responsive {...Responsive.onlyMobile}>
                <MonthSelector
                    afterDate={params.afterDate}
                    beforeDate={params.beforeDate}
                    onFilter={(val) => setParams({ ...params, afterDate: val.after, beforeDate: val.before })} />
            </Responsive>
            <Card className={`revenues-card${isShowPrintVersion ? ' stretch-ver' : ''}`}>
                <div className="df-col stretch-ver">
                    <div className="df-row-ac jc-sb revenues-header">
                        <div className="df-row-ac">
                            <Responsive minWidth={Responsive.onlyTablet.minWidth}>
                                <MonthSelector
                                    afterDate={params.afterDate}
                                    beforeDate={params.beforeDate}
                                    onFilter={(val) => setParams({ ...params, afterDate: val.after, beforeDate: val.before })} />
                            </Responsive>
                            <div className="totals">{locContext.total}: &euro; {queryResult.totalAmountExVat}</div>
                            {/* {renderChange(queryResult.percentageChangeTotalAmountExVat)} */}
                            <InformationIconButton onClick={() => showTotals()} />
                        </div>
                        <div className="df-row-ac">
                            <Responsive minWidth={Responsive.onlyTablet.minWidth}>
                                <PrimaryButton small outline className="export-button" onClick={() => setIsShowPrintVersion(!isShowPrintVersion)}>{isShowPrintVersion ? locContext.viewWebVersion : locContext.viewPrintVersion}</PrimaryButton>
                            </Responsive>
                            <Responsive {...Responsive.onlyMobile}>
                                <PrimaryButton small outline className="filter-button" onClick={() => setIsFilterOpen(!isFilterOpen)}>{locContext.filterOn}</PrimaryButton>
                            </Responsive>
                        </div>
                    </div>
                    <Responsive {...Responsive.onlyMobile}>
                        {isFilterOpen ?
                            <div className="df-col">
                                <Divider className="no-margin" />
                                <FilterRevenues params={params} onFilter={(pars) => setParams(pars)} />
                            </div> : null}
                    </Responsive>
                    <Responsive minWidth={Responsive.onlyTablet.minWidth}>
                        <FilterRevenues params={params} onFilter={(pars) => setParams(pars)} />
                    </Responsive>
                    {queryResult.values === undefined || queryResult.values.length === 0 ?
                        <div className="no-revenues">{locContext.noRevenues}</div>
                        :
                        isShowPrintVersion === false ?
                            queryResult.values.map(t =>
                                <RevenuePerDriver
                                    key={t.driverId}
                                    value={t}
                                    afterDate={queryResult.afterDate}
                                    beforeDate={queryResult.beforeDate} />)
                            :
                            <PDFViewer className="stretch-ver">
                                <ExportDocument queryResult={queryResult} />
                            </PDFViewer>}
                </div>
            </Card>
        </div>
    );
}