import React from 'react';
import { useLocalizationContext } from '../../hooks/useLocalizationContext';
import { toDefaultFormat } from '../../infrastructure/Utils';
import './RevenuesSummary.scss';

interface IProps {
    afterDate: Date | undefined;
    beforeDate: Date | undefined;
    drivers: string[];
    amountExVat: number | undefined;
    amountInVat: number | undefined;
    vatAmount: number | undefined;
    previousMonthAmountExVat: number | undefined;
    percentageChangeAmountExVat : number | undefined;    
}

export const RevenuesSummary = ({ afterDate, beforeDate, drivers, amountExVat, amountInVat, vatAmount, previousMonthAmountExVat, percentageChangeAmountExVat }: IProps) => {
    const locContext = useLocalizationContext();
    return (
        <div className="df-col revenues-summary-container">
            <div className="rs-title">
                {locContext.totalRevenues}
            </div>
            <div className="label">{locContext.date}</div>
            <div className="info">{toDefaultFormat(afterDate)} - {toDefaultFormat(beforeDate)}</div>
            <div className="label">{locContext.drivers}</div>
            {drivers.map(t => <div className="info" key={t}>{t}</div>)}
            <div className="label">{locContext.amountExVat}</div>
            <div className="df-row-ab">
                <div className="info">&euro; {amountExVat}</div>
                <div className="last-month-text">{locContext.lastMonth}:</div>
                <div className="last-month-value">&euro; {previousMonthAmountExVat}</div>
                {/* <div>{renderChange(percentageChangeAmountExVat)}</div> */}
            </div>
            <div className="label">{locContext.vat}</div>
            <div className="info">&euro; {vatAmount}</div>
            <div className="label">{locContext.amountInVat}</div>
            <div className="info">&euro; {amountInVat}</div>
        </div>
    );
}