import { useContext, useEffect, useState } from 'react';
import { Card, Divider, Responsive } from 'semantic-ui-react';
import { RouterContext } from '../../context/RouterContext';
import { SettlementProposalQueryParamsContext } from '../../context/SettlementProposalsQueryParamsContext';
import { WrapperFullScreenLoadContext } from '../../context/WrapperFullScreenLoadContext';
import { WrapperSearchContext } from '../../context/WrapperSearchTextContext';
import {
	BuildClient,
	ISettlementProposalQueryResult,
	ISettlementProposal,
	ISettlementProposalQueryParams,
	SettlementProposalQueryParams,
	SettlementProposalsClient,
} from '../../gen/ApiClients';
import { useClient, useDownloadClient } from '../../hooks/useClient';
import { useLocalizationContext } from '../../hooks/useLocalizationContext';
import { toDefaultFormat, tryCatchWithLoading } from '../../infrastructure/Utils';
import { PrimaryButton } from '../common/buttons/PrimaryButton';
import { BaseTableSortAndPage } from '../common/table/BaseTableSortAndPage';
import { PagingFooter } from '../common/table/PagingFooter';
import { createHeader, createSortableHeader } from '../common/table/TableUtils';
import { FilterSettlementProposals } from './FilterSettlementProposals';
import './SettlementProposals.scss';
import { SpsActions } from './SpsActions';
import React from 'react';

export const SettlementProposals = () => {
	const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
	const locContext = useLocalizationContext();
	const [queryResult, setQueryResult] = useState<ISettlementProposalQueryResult>();
	const { params, setParams } = useContext(SettlementProposalQueryParamsContext);
	const client = useClient(SettlementProposalsClient);
	const wrapperLoaderContext = useContext(WrapperFullScreenLoadContext);
	const routerContext = useContext(RouterContext);
	const downloadClient = useDownloadClient(routerContext);
	useContext(WrapperSearchContext).init(`${locContext.search}...`, params.searchString, (str: string) => {
		setParams({ ...params, searchString: str, pageIndex: 1 });
	});
	const buildClient = useClient(BuildClient);

	useEffect(
		() => {
			load();
		},
		// eslint-disable-next-line
		[params]
	);

	useEffect(() => {
		console.log(params);
	}, [params]);

	const load = async () => {
		setQueryResult(await tryCatchWithLoading(client.query(new SettlementProposalQueryParams(params)), wrapperLoaderContext.setLoading, locContext.serverError));
	};

	const download = async (inst: ISettlementProposal) => {
		wrapperLoaderContext.setLoading(true);
		const baseUrl = await buildClient.url();
		const url = `${baseUrl}/api/SettlementProposals/${inst.documentId}/download`;
		await downloadClient.download(url, `SP_${inst.documentId}.pdf`, () => wrapperLoaderContext.setLoading(false));
	};

	const headers = [
		createSortableHeader<ISettlementProposal>(locContext.date, 'postingDate', t => toDefaultFormat(t.postingDate)),
		createSortableHeader<ISettlementProposal>(locContext.fileNumber, 'documentId', t => t.documentId),
		createHeader<ISettlementProposal>(locContext.amountExVat, t => <div>&euro; {t.amount}</div>),
		// createEmptyHeader<ISettlementProposal>('actions', t => <, false, true),
	];

	return (
		<Card className='sp-card'>
			<div className='df-col stretch-ver'>
				<div className='df-row-ac jc-sb sp-header'>
					<div>{locContext.settlementProposals}</div>
					<Responsive {...Responsive.onlyMobile}>
						<PrimaryButton
							small
							outline
							className='filter-button'
							onClick={() => setIsFilterOpen(!isFilterOpen)}>
							{locContext.filterOn}
						</PrimaryButton>
					</Responsive>
				</div>
				<Divider className='no-margin' />
				<Responsive {...Responsive.onlyMobile}>
					{isFilterOpen ? (
						<div className='df-col'>
							<FilterSettlementProposals
								onFilter={pars => setParams({ ...pars, pageIndex: 1 })}
								params={params}
							/>
							<Divider className='no-margin' />
						</div>
					) : null}
				</Responsive>
				<Responsive minWidth={Responsive.onlyTablet.minWidth}>
					<FilterSettlementProposals
						onFilter={pars => setParams({ ...pars, pageIndex: 1 })}
						params={params}
					/>
				</Responsive>
				<div className='pos-rel'>
					<Responsive minWidth={Responsive.onlyTablet.minWidth}>
						<BaseTableSortAndPage<ISettlementProposal, ISettlementProposalQueryParams>
							params={params}
							onSortOrPage={t => {
								console.log('set params');
								setParams(t);
							}}
							queryResult={queryResult}
							canSort={true}
							headers={headers}
							uniqueIdentifier='documentId'
							singleIdentifier={locContext.form}
							onClickRow={inst => download(inst)}
						/>
					</Responsive>
					<Responsive {...Responsive.onlyMobile}>
						{queryResult && queryResult.values ? (
							<div className='df-col'>
								{queryResult.values.map(t => (
									<div
										className='df-col'
										key={t.documentId}>
										<div className='mobile-single-sp df-row'>
											<div className='fg1 df-col'>
												<div className='date font-bold info'>{toDefaultFormat(t.postingDate)}</div>
												<div className='info'>{t.documentId}</div>
												<div className='info'>&euro; {t.amount}</div>
											</div>
											<SpsActions
												item={t}
												isMobile={true}
											/>
										</div>
										<Divider className='no-margin' />
									</div>
								))}
								<PagingFooter
									className='padding-10px'
									singleIdentifier={locContext.form}
									qr={queryResult ? queryResult : {}}
									onNext={() =>
										setParams({
											...params,
											pageIndex: params.pageIndex ? params.pageIndex + 1 : 1,
										})
									}
									onPrevious={() =>
										setParams({
											...params,
											pageIndex: params.pageIndex ? params.pageIndex - 1 : 1,
										})
									}
								/>
							</div>
						) : null}
					</Responsive>
				</div>
			</div>
		</Card>
	);
};
