import { AxiosInstance, AxiosRequestConfig } from "axios";
import axios from "axios";
import { TokenStore } from "../infrastructure/TokenStore";
import { useContext } from "react";
import { RouterContext, IRouterProps } from "../context/RouterContext";
import * as fromRoutes from "../routes";
import { LanguageStore } from "../infrastructure/LanguageStore";
import { ApiUrl } from "../keys";

export interface IDownloadClient {
  download: (url: string, fileName: string, doOnEnd?: () => void) => void;
}

export function useDownloadClient(router: IRouterProps): IDownloadClient {
  return {
    download: (url, fileName, doOnEnd) => {
      let inst = axios.create({ responseType: "blob" });
      inst.interceptors.request.use(tokenInterceptor, function (error) {
        if (doOnEnd) {
          doOnEnd();
        }
        return Promise.reject(error);
      });
      inst.interceptors.request.use(languageInterceptor, (error) =>
        Promise.reject(error)
      );
      inst.interceptors.response.use(
        (response) => response,
        (error) => unauthorizedRedirectInterceptor(router, error)
      );
      inst.get(url).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        if (doOnEnd) {
          doOnEnd();
        }
      });
    },
  };
}

export function useClient<T>(
  type: new (baseUrl?: string, instance?: AxiosInstance) => T
): T {
  let inst = axios.create();
  const router = useContext(RouterContext);

  inst.interceptors.request.use(tokenInterceptor, function (error) {
    return Promise.reject(error);
  });
  inst.interceptors.request.use(languageInterceptor, (error) =>
    Promise.reject(error)
  );
  inst.interceptors.response.use(
    (response) => response,
    (error) => unauthorizedRedirectInterceptor(router, error)
  );
  return new type(ApiUrl, inst);
}

function tokenInterceptor(config: AxiosRequestConfig): AxiosRequestConfig {
  const token = TokenStore.getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}

function unauthorizedRedirectInterceptor(router: any, error: any): any {
  if (error && error.response && error.response.status === 401) {
    router.history.push(fromRoutes.LoginRoute);
  }
  return Promise.reject(error);
}

function languageInterceptor(config: AxiosRequestConfig): AxiosRequestConfig {
  const language = LanguageStore.get();
  if (language) {
    config.headers.Language = language;
  }
  return config;
}
