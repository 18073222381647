import React, { useState, useEffect } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import moment from 'moment';
import { IconButton } from '../buttons/IconButton';
import { FilterItem } from './FilterItem';
import { MonthInput } from 'semantic-ui-calendar-react';
import { useCombineClassNames } from '../../../hooks/useCombineClassNames';
import './MonthSelector.scss';
import { Divider } from 'semantic-ui-react';

interface IProps {
    icon?: IconProp;
    onFilter: (value: IDateOutput) => void;
    afterDate: Date | undefined;
    beforeDate: Date | undefined;
    className?: string;
}

export interface IDateOutput {
    after: Date | undefined;
    before: Date | undefined;
}

export const MonthSelector = ({ icon = ['fas', 'calendar'], onFilter, afterDate, beforeDate, ...rest }: IProps) => {
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const [yearValue, setYearValue] = useState<number>(0);
    const [monthValue, setMonthValue] = useState<number>(0);

    useEffect(() => {
        setYearValue(afterDate ? afterDate.getFullYear() : new Date().getFullYear());
        setMonthValue(afterDate ? afterDate.getMonth() : new Date().getMonth());
    }, [afterDate, beforeDate]);

    const handleFilter = (addMonths: number) => {
        const date = moment(new Date(yearValue, monthValue, 1)).add(addMonths, "months").toDate();
        onFilter({
            after: moment(date).startOf("month").toDate(),
            before: moment(date).endOf("month").toDate(),
        });
    }

    return (
        <div className={useCombineClassNames('df-row', rest)}>
            <div className="df-row-ac filter-select-month">
                <IconButton className="np-button np-button-left" icon={['fas', 'chevron-left']} onClick={() => handleFilter(-1)} />
                <FilterItem
                    icon={icon}
                    label={moment(new Date(yearValue, monthValue, 1)).format('MMM/YYYY')}
                    count={0}
                    onClickAway={() => handleFilter(0)}
                    noMaxHeight={true}
                    onOpened={() => { }}
                    className="select-month-trigger jc-c">
                    <div className="df-col dfi-container">
                        <div className="df-row-ac jc-c year-selector">
                            <IconButton size="large" color="gray" icon={['fas', 'chevron-left']} onClick={() => setYearValue(yearValue - 1)} />
                            <div className="year-text">Jaar: {yearValue}</div>
                            <IconButton size="large" color="gray" icon={['fas', 'chevron-right']} onClick={() => setYearValue(yearValue + 1)} />
                        </div>
                        <Divider className="no-margin" />
                        <MonthInput
                            value={monthNames[monthValue]}
                            onChange={(e, data) => setMonthValue(monthNames.indexOf(data.value))}
                            // onChange={(e, data) => handleSelect(data)}
                            inline={true}
                            inlineLabel={true}
                        // localization='nl' 
                        />
                    </div>
                </FilterItem>
                <IconButton className="np-button np-button-right" icon={['fas', 'chevron-right']} onClick={() => handleFilter(+1)} />
            </div>
        </div>
    );
}